import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';
import { RolesResponsibility } from './roles-responsibility.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public rolesMessage = new BehaviorSubject('defalute');
  currentMessage = this.rolesMessage.asObservable();
  constructor(private readonly http: HttpClient) {}

  getRolesData(projectCode): Observable<RolesResponsibility[]> {
    return this.http
      .get(
        `${environment.baseUrl}/project-task/major-task?projectCode=${projectCode}`
      )
      .pipe(
        map((res: any) => {
          return res;
         
        })
      );
  }
  getRoleData(): Observable<any> {
    return this.http.get(environment.baseUrl + endpoint.ORGANIZATION);
  }

  //add created Kpi
  addRoles(role: any): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrl}/project-task/major-task`,
      role,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }
  updateRoles(roleData) {
    return this.http.put(
      `${environment.baseUrl}/project-task/major-task?action=update`,
      roleData
    );
  }
  deleteRoles(roleData): Observable<any> {
    return this.http.put(
      `${environment.baseUrl}/project-task/major-task?action=delete`,
      roleData
    );
  }

  setRoleDataStore(data) {
    sessionStorage.setItem('roleDataStore', JSON.stringify(data));
  }

  getRoleDataStore() {
    return JSON.parse(sessionStorage.getItem('roleDataStore'));
  }
}
