import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityScheduleService } from '../../../core/services/activity-schedule/activity-schedule.service';
import { ModalConfig } from '../../../core/services/models/modal-config';
import { RolesService } from '../../../core/services/roles-responsibility/roles.service';
import { AlertComponent } from '../../../shared/alert/alert.component';
import { message } from '../../../shared/config/message.config';
import { Status } from '../../../shared/config/status.config';
import { ModalStateService } from '../../../shared/services/modal-state.service';
import { KpiService } from '../../../core/services/kpi/kpi.service';

const SelfPath = '/createroles';
const ViewRolesPath = '/viewroles';
const ASPath = '/activity-schedule';
@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit {
  eventForm: FormGroup;
  actionType: string;
  taskType: string;
  taskTypeList: any;
  statusList: any;
  startDtMT: string;
  endDtMT: string;
  showSaveBtn = false;
  majorTaskData: any;
  taskData: any;
  duplicateFlag = false;
  showAddEventBtn = false;
  userList: any;
  minDate: any;
  maxDate: any;
  currentPjCd: any;
  assignedToChangedFlag = false;
  initialTaskLead: any;
  currentTaskName: string;
  taskNameChangeFlag = false;
  isAssign = false;
  serverMessage = 'System is currently unavailable, please try after sometime.';
  showTextArea: boolean = false;
  toggleTextArea: boolean = false;
  showTask: boolean = true;
  showEditComments: boolean = false;
  showCommentArrow: boolean = false;
  div7 = true;
  public toggleButton = true;
  comment = '';
  name = '';
  date = new Date();
  postComment = [];
  pov: any;
  currentUser: any;
  currentProjectName: any;
  kpiData = false;
  kpis: any[] = [];
  selectedkpiList: any[] = [];
  listOfKpis: any[] = [];
  commentIndex: any;
  kpis1: any;
  t: any[] = [];
  goalKpi: any;
  taskResData: any = [];
  projectResData: any = [];
  showEndDate = false;
  delete = true;
  externalUser = 'SIPM-ExternalUser';
  projectCodeKpi: any;
  editMessage = false;
  @Input() notificationData: any;
  @Input() index: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _activityScheduleService: ActivityScheduleService,
    private readonly modalService: NgbModal,
    private readonly modalState: ModalStateService,
    private readonly activeModal: NgbActiveModal,
    private readonly router: Router,
    private readonly roleService: RolesService,
    private readonly authService: MsalService,
    private readonly kpiService: KpiService,
    private readonly _elementRef: ElementRef
  ) {
    this.createForm();
    this.taskTypeList = sessionStorage.getItem('MISC#TASK_TYPE');
    this.taskTypeList = this.taskTypeList ? this.taskTypeList.split(',') : [];
    this.taskTypeList.push('Major Task');
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    this.statusList = [
      {
        id: 1,
        name: 'Green',
        avatar: '../../../../assets/green.svg',
      },
      {
        id: 2,
        name: 'Yellow',
        avatar: '../../../../assets/amber.svg',
      },
      {
        id: 3,
        name: 'Red',
        avatar: '../../../../assets/red.svg',
      },
    ];
    const todayDate = new Date();
    this.minDate = {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate(),
    };
    this.maxDate = {
      year: todayDate.getFullYear() + 10,
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate(),
    };
  }
  ngOnInit(): void {
    this.projectCodeKpi = sessionStorage.getItem('currentPrj');
    this._activityScheduleService.config.subscribe((data) => {
      this.taskType = data.type ? data.type : null;
      this.displayEndDate();
      this.actionType = this.notificationData
        ? this.actionType === 'view'
        : data.action;
      this.majorTaskData = data.pvalue;
      this.currentProjectName = this.majorTaskData.projectName;
      this.currentPjCd = this.majorTaskData.projectCode;
      this.startDtMT = this.majorTaskData.startDate;
      this.endDtMT = this.majorTaskData.endDate;
      this.taskData = data.cvalue;
      this.duplicateFlag = false;
      if (
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === 'SIPM-ToyotaUser' ||
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === this.externalUser
      ) {
        if (
          this.authService.instance.getAllAccounts()[0].username ===
          this.majorTaskData.lead.emailId
        ) {
          this.isAssign = true;
        } else {
          this.isAssign = false;
        }
      } else {
        this.isAssign = true;
      }
      this.getActionType();
    });
    this.displayNotificationData();
    this.getProjectbyCode1(this.projectCodeKpi);
  }
  displayEndDate() {
    if (this.taskType === 'majorTask' || this.taskType === 'task') {
      this.showEndDate = true;
    }
  }
  getActionType() {
    //const group = this.majorTaskData.group;
    if (this.actionType === 'view') {
      if (this.taskType === 'majorTask') {
        this.userList = [];
        this.userList.push(this.majorTaskData.lead);
        this.setForm(this.majorTaskData);
        this.showAddEventBtn = true;
      } else {
        this.taskTypeList = this.taskTypeList.slice(0, 3);
        this.createGroupUserList(this.majorTaskData, this.majorTaskData.lead);
        this.setForm(this.taskData);
      }
      this.eventForm.disable();
    } else if (this.actionType === 'create') {
      this.createGroupUserList(this.majorTaskData, this.majorTaskData.lead);
      this.taskTypeList = this.taskTypeList.slice(0, 3);
      this.createForm();
      this.setCreateForm(this.majorTaskData);
      this.showSaveBtn = true;
      this.eventForm.get('group').disable();
    }
  }
  displayNotificationData() {
    if (this.notificationData) {
      this.majorTaskData = this.notificationData;
      this.taskType = this.majorTaskData.tasks[this.index].type.toLowerCase();
      this.currentPjCd =
        this.notificationData && this.notificationData.childProjectCode
          ? this.notificationData.childProjectCode
          : sessionStorage.getItem('currentPrj');
      this.setForm(this.notificationData.tasks[this.index]);
      this.eventForm.disable();
    }
    this.getProjectbyCode1(this.projectCodeKpi);
  }

  getProjectbyCode1(projCode) {
    this.kpiService.getKPIData(projCode).subscribe((data: any) => {
      const parentKpiData = data.ParentProjectKpis.Kpis;
      parentKpiData.forEach((kpi) => {
        kpi.projectName = data.ParentProjectKpis.name;
        kpi.isParent = true;
      });

      const childKpiData = [];
      data.ChildProjectKpis.forEach((project) => {
        project.Kpis.forEach((kpi) => {
          kpi.projectName = project.name;
          kpi.isParent = false;
          childKpiData.push(kpi);
        });
      });
      this.kpis = [...parentKpiData, ...childKpiData];
    });
  }

  createForm() {
    this.eventForm = this.formBuilder.group({
      majorTaskName: [''],
      projectCode: [''],
      taskName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      type: ['', [Validators.required]],
      group: [''],
      organization: [''],
      leadNm: ['', [Validators.required]],
      lead: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      status: ['', [Validators.required]],
      progress: ['', [Validators.required]],
      isClicked: [''],
      majorTaskStartDate: [''],
      majorTaskEndDate: [''],
      comments: [''],
      eventType: [''],
      kpis: [''],
      majorTaskUUID: '',
      subTaskUUID: '',
    });
  }

  setForm(taskDtl) {
    this.eventForm.patchValue({
      majorTaskName: taskDtl.majorTaskName
        ? taskDtl.majorTaskName
        : this.majorTaskData.majorTaskName,
      projectCode: this.currentPjCd,
      taskName: taskDtl.taskName ? taskDtl.taskName : taskDtl.majorTaskName,
      description: taskDtl.description,
      type: taskDtl.type ? taskDtl.type : 'Major Task',
      group: taskDtl.group ? taskDtl.group : this.majorTaskData.group,
      organization: taskDtl.organization
        ? taskDtl.organization
        : this.majorTaskData.organization,
      lead: taskDtl.lead,
      leadNm: taskDtl.lead.emailId,
      startDate: taskDtl.startDate,
      endDate: taskDtl.endDate,
      status: taskDtl.status,
      progress: `${Math.round(taskDtl.progress)}%`,
      isClicked: taskDtl.isClicked,
      majorTaskStartDate: this.majorTaskData.startDate,
      majorTaskEndDate: this.majorTaskData.endDate,
      eventType: taskDtl.lead,
      kpiValues: taskDtl.kpis,
    });
    this.initialTaskLead = taskDtl.lead.emailId;
    this.currentTaskName = taskDtl.taskName;
    this.postComment = taskDtl.comments ? taskDtl.comments : [];
    this.postComment.forEach((x1) => {
      if (
        x1.name !== this.currentUser.name &&
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === this.externalUser
      ) {
        x1.delete = false;
      } else if (
        x1.name === this.currentUser.name &&
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] !== this.externalUser
      ) {
        x1.delete = true;
      }
    });
    this.listOfKpis = taskDtl.kpis ? taskDtl.kpis : this.listOfKpis;
    this.selectedkpiList = [...this.listOfKpis, ...this.selectedkpiList];
  }

  setCreateForm(taskDtl) {
    this.eventForm.patchValue({
      majorTaskName: taskDtl.majorTaskName
        ? taskDtl.majorTaskName
        : this.majorTaskData.majorTaskName,
      projectCode: this.currentPjCd,
      group: taskDtl.group ? taskDtl.group : this.majorTaskData.group,
      organization: taskDtl.organization
        ? taskDtl.organization
        : this.majorTaskData.organization,
      leadNm: taskDtl.lead.emailId,
      lead: taskDtl.lead,
      isClicked: false,
      status: 'Green',
      majorTaskStartDate: this.majorTaskData.startDate,
      majorTaskEndDate: this.majorTaskData.endDate,
    });
    this.eventForm.get('endDate').disable();
  }

  goToComment() {
    this.showTextArea = true;
    this.showTask = false;
    this.toggleTextArea = true;
  }
  onButtonClick() {
    this.showTextArea = true;
  }
  onBack() {
    this.showTask = true;
    this.showTextArea = false;
    this.showCommentArrow = true;
  }
  onCommentAdd() {
    this.toggleTextArea = true;
    this.editTask();
    if (this.commentIndex === undefined) {
      const commentObj = {
        remarks: this.comment,
        name: this.currentUser.name,
        date: this.date,
      };
      this.postComment.unshift(commentObj);
      this.onSubmit();
      this.showTask = true;
      this.showTextArea = false;
    } else {
      const postComment = [...this.postComment];
      postComment[this.commentIndex].remarks = this.comment;
      this.postComment = [...postComment];
      this.onSubmit();
      this.showTask = true;
      this.showTextArea = false;
    }
    this.comment = '';
    this.commentIndex = undefined;
    this.postComment.forEach((x) => {
      if (
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === 'SIPM-Admin' ||
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === 'SIPM-ProjectLead'
      ) {
        x.delete = true;
      } else if (
        x.name === this.currentUser.name &&
        (this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
          .roles[0] === this.externalUser
      ) {
        x.delete = true;
      } else {
        x.delete = false;
      }
    });
  }
  onDeleteComment(index: any) {
    this.editTask();
    this.postComment.splice(index, 1);
    this.onSubmit();
    this.showTask = true;
    this.showTextArea = false;
  }
  onCommentCancel() {
    this.comment = '';
  }
  onEditComment(i) {
    this.comment = this.postComment[i].remarks;
    this.commentIndex = i;
  }

  showKpis() {
    if (this.actionType === 'edit' || this.actionType === 'create') {
      this.kpiData = true;
      this.showTask = false;
      this.showSaveBtn = false;
    }
  }
  onChange(name: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedkpiList.push(name);
    } else {
      this.selectedkpiList.splice(this.selectedkpiList.indexOf(name), 1);
    }
  }

  onKpiSelect() {
    this.showTask = true;
    this.kpiData = false;
    this.showSaveBtn = true;
    this.listOfKpis = this.selectedkpiList;
    this.goalKpi = JSON.parse(sessionStorage.getItem('currentProjectDetails'));
  }
  openEventCancelModal() {
    const modalRef = this.modalService.open(AlertComponent, {
      centered: true,
    });
    const config = new ModalConfig();
    config.modalHeader = 'Cancel';
    config.modalBody = message.CANCEL;
    config.cancelBtn = true;
    config.cancelBtnName = 'No';
    config.saveBtn = true;
    config.saveBtnName = 'Yes';
    config.deleteBtn = false;
    this.modalState.changeConfig(config);
    modalRef.result.then((result) => {
      if (result) {
        this.modalService.dismissAll();
      } else {
        modalRef.close();
      }
    });
  }

  editTask() {
    this.showEditComments = true;
    this.showCommentArrow = true;
    this.toggleTextArea = true;
    this.div7 = false;
    this.actionType = 'edit';
    if (this.showTask) {
      if (this._elementRef.nativeElement.querySelector(`#kpisTag`) !== null) {
        const domElement =
          this._elementRef.nativeElement.querySelector(`#kpisTag`);
        domElement.style.backgroundColor = 'white';
      }
    }
    this.eventForm.enable();
    this.eventForm.get('group').disable();
    //this.eventForm.get('type').disable();
    if (this.taskType === 'checkpoint' || this.taskType === 'milestone') {
      this.setCheckpointExtraValidations();
    } else {
      this.setTaskExtraValidations();
    }
    this.showSaveBtn = true;
  }

  createTaskFromMT() {
    this.eventForm.reset();
    this.eventForm.enable();
    this.setCreateForm(this.majorTaskData);
    this.createGroupUserList(this.majorTaskData.group, this.majorTaskData.lead);
    this.taskTypeList = this.taskTypeList.slice(0, 3);
    this.showSaveBtn = true;
    this.showAddEventBtn = false;
    this.eventForm.get('group').disable();
    this.eventForm.get('progress').setValue('0%');
    this.actionType = 'create';
    this.taskType = 'task';
  }

  getStatus(status) {
    return Status.get(status);
  }

  close(): void {
    this.activeModal.close();
  }

  assignLeadObj() {
    const val = this.eventForm.get('leadNm').value;
    if (this.actionType === 'edit') {
      if (this.initialTaskLead !== val) {
        this.assignedToChangedFlag = true;
      } else {
        this.assignedToChangedFlag = false;
      }
    }

    let leadObj = this.userList.filter((x) => x.emailId === val);
    leadObj = leadObj.length > 0 ? leadObj[0] : {};
    if (Object.keys(leadObj).length > 0) {
      const obj = {
        emailId: leadObj.emailId,
        firstName: leadObj.firstName,
        lastName: leadObj.lastName,
      };
      this.eventForm.get('lead').setValue(obj);
    }
  }

  changeTaskName() {
    const val = this.eventForm.get('taskName').value;
    if (this.actionType === 'edit') {
      if (this.currentTaskName && this.currentTaskName !== val) {
        this.taskNameChangeFlag = true;
      } else {
        this.taskNameChangeFlag = false;
      }
    }
  }

  createGroupUserList(majorTask, lead) {
    const groupListFromLS = sessionStorage.getItem('groupUserList');
    const parsedGroupListFromLS = JSON.parse(groupListFromLS);
    this.userList = [];
    if (parsedGroupListFromLS && parsedGroupListFromLS.length > 0) {
      parsedGroupListFromLS.forEach((x) => {
        x.users.forEach((user) => {
          if (majorTask.supportTeam.includes(user.emailId))
            this.userList.push(user);
        });
      });
    }

    // projectUserList.forEach((user) => {
    //   if (majorTask.supportTeam.contains(user.emailId)) {
    //     this.userList.push(user);
    //   }
    // });
    //this.userList = this.userList.length > 0 ? this.userList[0].users : [];
    if (lead && Object.keys(lead).length > 0) {
      this.userList.push(lead);
    }
    var dataArr = this.userList.map((item) => {
      return [item.emailId, item];
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array
    this.userList = [...maparr.values()];
  }

  setTaskExtraValidations() {
    this.eventForm.get('progress').setValidators(Validators.required);
    this.eventForm.get('endDate').setValidators(Validators.required);
    this.eventForm.get('status').setValidators(Validators.required);
  }

  setCheckpointExtraValidations() {
    this.eventForm.get('endDate').setErrors(null);
  }

  changeEventType() {
    const val = this.eventForm.get('type').value;
    if (val === 'Checkpoint') {
      this.taskType = 'checkpoint';
      this.showEndDate = false;
      this.setCheckpointExtraValidations();
    } else if (val === 'Task') {
      this.taskType = 'task';
      this.showEndDate = true;
      this.setTaskExtraValidations();
    } else if (val === 'Milestone') {
      this.taskType = 'milestone';
      this.showEndDate = false;
      this.setCheckpointExtraValidations();
    }
  }

  addPercent() {
    let val = this.eventForm.get('progress').value;

    if (val.length > 0 && val.indexOf('%') === -1) {
      val += '%';
      this.eventForm.get('progress').setValue(val);
    }
    if (val === '') {
      this.eventForm.get('progress').setErrors({ required: true });
    } else if (!val.match('^[0-9][0-9]?%$|^100%$')) {
      this.eventForm.get('progress').setErrors({ pattern: true });
    }
  }

  checkStartDtValidation() {
    const selectedDt = this.eventForm.get('startDate').value;
    if (this.taskType === 'task') {
      if (new Date(selectedDt) < new Date(this.startDtMT)) {
        this.eventForm.get('startDate').setErrors({ lessThanMT: true });
      } else if (new Date(selectedDt) > new Date(this.endDtMT)) {
        this.eventForm.get('startDate').setErrors({ greaterThanMT: true });
      }
    } else {
      if (
        new Date(selectedDt) < new Date(this.startDtMT) ||
        new Date(selectedDt) > new Date(this.endDtMT)
      ) {
        this.eventForm.get('startDate').setErrors({ betweenMT: true });
      }
    }
    // to enable end date
    if (this.eventForm.get('startDate').valid && this.taskType === 'task') {
      this.eventForm.get('endDate').enable();
    }
  }

  checkendDtValidation() {
    const selectedDt = this.eventForm.get('endDate').value;
    const startDtT = this.eventForm.get('startDate').value;
    if (new Date(selectedDt) < new Date(startDtT)) {
      this.eventForm.get('endDate').setErrors({ lessThanT: true });
    } else if (new Date(selectedDt) > new Date(this.endDtMT)) {
      this.eventForm.get('endDate').setErrors({ greaterThanMT: true });
    }
  }
  deleteConfirmModal() {
    const modalRef = this.modalService.open(AlertComponent, {
      centered: true,
    });
    const config = new ModalConfig();
    config.modalHeader = 'Delete';
    config.modalBody =
      this.taskType === 'majorTask' && this.majorTaskData.tasks.length > 0
        ? message.DELETE_MAJORTASK
        : message.DELETE;
    config.cancelBtn = true;
    config.cancelBtnName = 'No';
    config.saveBtn = true;
    config.saveBtnName = 'Yes';
    config.deleteBtn = false;
    this.modalState.changeConfig(config);
    modalRef.result.then((result) => {
      if (result) {
        this.deleteTask();
      } else {
        modalRef.close();
      }
    });
  }

  deleteTask() {
    const task = this.eventForm.getRawValue();
    task.majorTaskUUID = this.majorTaskData.majorTaskUUID;
    task.subTaskUUID = this.taskData.subTaskUUID;
    task.progress = parseInt(task.progress);
    delete task.majorTaskStartDate;
    delete task.majorTaskEndDate;
    if (
      this.taskType === 'checkpoint' ||
      this.taskType === 'task' ||
      this.taskType === 'milestone'
    ) {
      delete task.endDate;
      delete task.progress;
      delete task.status;
      const taskData = { task: task };
      const data = {};
      this._activityScheduleService.deleteTask(taskData).subscribe(
        (res: any) => {
          if (res.status.code === '200') {
            data['errorMsg'] = '';
            data['successOrFailureFlag'] = 'S';
            this.modalService.dismissAll();
            if (sessionStorage.getItem('url') === SelfPath) {
              this.roleService.rolesMessage.next('rolesDeleted');
              this.router.navigate([ViewRolesPath]);
            } else {
              this._activityScheduleService.activityScheduleMessage.next(
                'majorTaskDeleted'
              );
              this.router.navigate([ASPath]);
            }
          } else if (res.status.code === '400') {
            data['successOrFailureFlag'] = 'F';
            data['errorMsg'] = res.status.messages[0].description;
          } else {
            this.errorModal(res.status.messages[0].description, 'm');
          }
          this._activityScheduleService.changeMessage(data);
        },
        (deleteFailMsg) => {
          this.errorModal(this.serverMessage, 'm');
        }
      );
    } else {
      if (this.taskType === 'majorTask') {
        const data = {
          majorTaskName: task.majorTaskName,
          projectCode: task.projectCode,
          majorTaskUUID: this.majorTaskData.majorTaskUUID,
        };
        this.deleteMajorTask(data);
      }
    }
  }
  deleteMajorTask(data) {
    const majorTaskData = { majorTask: data };
    this.roleService.deleteRoles(majorTaskData).subscribe(
      (response: any) => {
        if (response.status.code === '200') {
          data['errorMsg'] = '';
          data['successOrFailureFlag'] = 'S';
          this.modalService.dismissAll();
          if (sessionStorage.getItem('url') === SelfPath) {
            this.roleService.rolesMessage.next('rolesDeleted');
            this.router.navigate([ViewRolesPath]);
          } else {
            this._activityScheduleService.activityScheduleMessage.next(
              'majorTaskDeleted'
            );
            this.router.navigate([ASPath]);
          }
        } else if (response.status.code === '400') {
          data['successOrFailureFlag'] = 'F';
          data['errorMsg'] = response.status.messages[0].description;
        } else {
          this.errorModal(response.status.messages[0].description, 'm');
        }
        this._activityScheduleService.changeMessage(data);
      },
      (deleteFailMsg) => {
        this.errorModal(this.serverMessage, 'm');
      }
    );
  }
  errorModal(messages: string, size: string): void {
    const modalRef = this.modalService.open(AlertComponent, {
      centered: true,
      size: size,
    });
    const configCR = new ModalConfig();
    configCR.modalHeader = 'Alert';
    configCR.modalBody = messages;
    configCR.cancelBtn = false;
    configCR.cancelBtnName = 'No';
    configCR.saveBtn = true;
    configCR.saveBtnName = 'OK';
    configCR.deleteBtn = false;
    this.modalState.changeConfig(configCR);
    modalRef.result.then((result) => {
      modalRef.close();
    });
  }
  onSubmit() {
    if (this.eventForm.valid) {
      let task = this.eventForm.getRawValue();
      task.progress = parseInt(task.progress);
      delete task.majorTaskStartDate;
      delete task.majorTaskEndDate;
      task['projectLead'] = sessionStorage.getItem('programLeadEmail');
      task['projectName'] = this.currentProjectName;
      task['comments'] = this.postComment;
      task['projectCode'] = this.currentPjCd;
      task['kpis'] = this.selectedkpiList;
      task = this.tuneTaskRequest(task);
      task.majorTaskUUID = this.majorTaskData.majorTaskUUID;
      if (this.actionType === 'edit') {
        task.subTaskUUID = this.taskData.subTaskUUID;
      } else {
        delete task.subTaskUUID;
      }
      const taskData = { task: task };
      if (this.actionType === 'create') {
        this.createServiceApi(taskData);
      } else if (this.actionType === 'edit') {
        this.editServiceApi(taskData);
      }
    }
  }

  tuneTaskRequest(task) {
    if (this.taskType === 'checkpoint' || this.taskType === 'milestone') {
      delete task.endDate;
    }
    if (this.actionType === 'edit') {
      task['isAssignedToChanged'] = this.assignedToChangedFlag;
      if (this.assignedToChangedFlag) {
        task['wbsProjectCode'] = sessionStorage.getItem('currentwbsPrjCode');
      }
      if (this.taskNameChangeFlag) {
        task['currentName'] = this.currentTaskName;
      }
    } else if (this.actionType === 'create') {
      task['wbsProjectCode'] = sessionStorage.getItem('currentwbsPrjCode');
    }
    return task;
  }

  createServiceApi(taskData) {
    this._activityScheduleService
      .createTask(taskData)
      .subscribe((resp: any) => {
        const data = {};
        data['mtName'] =
          resp.data && resp.data.task ? resp.data.task.majorTaskName : '';
        data['type'] = this.taskType;
        data['request'] = 'create';
        if (resp.status.code === '400') {
          data['successOrFailureFlag'] = 'F';
          data['errorMsg'] = resp.status.messages[0].description;
        } else if (resp.status.code === '200') {
          data['errorMsg'] = '';
          data['successOrFailureFlag'] = 'S';
          this.modalService.dismissAll();
        } else {
          data['errorMsg'] = '';
          data['successOrFailureFlag'] = 'F';
          this.modalService.dismissAll();
        }
        this._activityScheduleService.changeMessage(data);
      });
  }

  editServiceApi(taskData) {
    this._activityScheduleService.editTask(taskData).subscribe((resp: any) => {
      const data = {};
      data['request'] = 'edit';
      data['type'] = this.taskType;
      data['mtName'] =
        resp.data && resp.data.task ? resp.data.task.majorTaskName : '';
      if (resp.status.code === '200') {
        data['successOrFailureFlag'] = 'S';
        data['errorMsg'] = '';
        this.editMessage = true;
        if (this.showTextArea) {
          this.modalService.dismissAll();
        }
      } else if (resp.status.code === '400') {
        data['successOrFailureFlag'] = 'F';
        data['errorMsg'] = resp.status.messages[0].description;
      } else {
        data['successOrFailureFlag'] = 'F';
        data['errorMsg'] = '';
        this.modalService.dismissAll();
      }
      this._activityScheduleService.changeMessage(data);
    });
  }
  editMajorTask() {
    this.roleService.setRoleDataStore(this.majorTaskData);
    this.modalService.dismissAll();
    this.router.navigate(['/viewroles/editRole']);
  }
  copyData() {
    if (
      this.taskType.toLowerCase() === 'task' ||
      this.taskType.toLowerCase() === 'milestone' ||
      this.taskType.toLowerCase() === 'checkpoint'
    ) {
      this.actionType = 'create';
      this.showSaveBtn = true;
      this.showTextArea = false;
      this.eventForm.enable();
      this.eventForm.get('type').disable();
      this.eventForm.get('group').disable();
      if (
        this.taskType.toLowerCase() === 'milestone' ||
        this.taskType.toLowerCase() === 'checkpoint'
      ) {
        this.eventForm.get('endDate').setErrors(null);
      }
    } else {
      this.modalService.dismissAll();
      this.majorTaskData.actionType = 'create';
      this.roleService.setRoleDataStore(this.majorTaskData);
      this.router.navigate(['/viewroles/createRole']);
    }
  }
}
