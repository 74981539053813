<nav
  class="sidebar"
  id="side-nav"
  [ngClass]="{ sidebarPushRight: isActive, collapsed: collapsed }"
>
  <div class="list-group">
    <a class="list-group-item" id="brand" [routerLink]="['/dashboard']">
      <img src="assets/logo.png" class="img-responsive" alt="logo" />
    </a>
    <a
      [routerLink]="['/dashboard']"
      [routerLinkActive]="['active']"
      class="list-group-item"
      #home="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="home.isActive">
        <img src="assets/home_active.svg" class="img-responsive" alt="home" />
      </em>
      <em class="fa fa-fw" *ngIf="!home.isActive">
        <img src="assets/home.svg" class="img-responsive" alt="home" /> </em
      >&nbsp;
      <span>{{ "Home" }}</span>
    </a>
    <div class="nested-menu clearfix">
      <a
        class="list-group-item"
        [routerLinkActive]="['active']"
        (click)="addExpandClass('pages')"
      >
        <em
          class="fa fa-fw"
          *ngIf="
            group.isActive ||
              phase.isActive ||
              project.isActive ||
              router.isActive('/master/organization', false);
            else inactive
          "
        >
          <img
            src="assets/master_active.svg"
            class="img-responsive"
            alt="master"
          />
        </em>
        <ng-template #inactive>
          <em class="fa fa-fw" *ngIf="!group.isActive">
            <img src="assets/master.svg" class="img-responsive" alt="master" />
          </em>
        </ng-template>
        &nbsp;
        <span>{{ "Create" }}</span>
        <em class="fa fa-fw float-right">
          <img
            src="assets/next.svg"
            class="img-responsive rotated"
            alt="down-arrow"
          />
        </em>
      </a>
      <ul class="empty-spacing">
        <li class="nested" [class.expand]="showMenu == 'pages'">
          <ul class="submenu">
            <li>
              <a
                [routerLink]="['/master/group']"
                [routerLinkActive]="['active']"
                class="list-group-item"
                #group="routerLinkActive"
              >
                <em class="fa fa-monument"></em>&nbsp;
                <span>{{ "Group" }}</span>
              </a>
            </li>
            <li>
              <a
                [routerLink]="['/master/phase']"
                [routerLinkActive]="['active']"
                class="list-group-item"
                #phase="routerLinkActive"
              >
                <em class="fa fa-monument"></em>&nbsp;
                <span>{{ "Phase" }}</span>
              </a>
            </li>
            <li *RoleShow="['SIPM-ProjectLead', 'SIPM-Admin']">
              <a
                [routerLink]="['/master/organization']"
                [routerLinkActive]="['active']"
                class="list-group-item"
              >
                <em class="fa fa-monument"></em>&nbsp;
                <span>{{ "Partner" }}</span>
              </a>
            </li>
            <li>
              <a
                [routerLink]="['/master/project']"
                [routerLinkActive]="['active']"
                class="list-group-item"
                #project="routerLinkActive"
              >
                <em class="fa fa-monument"></em>&nbsp;
                <span>{{ "Project/Program" }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <a
      [routerLink]="['/orgdatasheet']"
      [routerLinkActive]="['active']"
      class="list-group-item"
      #orgdatasheet="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="orgdatasheet.isActive">
        <img
          src="assets/organization_active.svg"
          class="img-responsive"
          alt="partnerDatasheet"
        />
      </em>
      <em class="fa fa-fw" *ngIf="!orgdatasheet.isActive">
        <img
          src="assets/organization.svg"
          class="img-responsive"
          alt="partnerDatasheet"
        /> </em
      >&nbsp;
      <span>{{ "Partner Org Chart" }}</span>
    </a>
    <a
      *RoleShow="['SIPM-ProjectLead', 'SIPM-Admin']"
      class="list-group-item"
      [ngClass]="{
        emptyBackground: budgetEmptyFlag === true
      }"
      [routerLinkActive]="['active']"
      [routerLink]="['/budget']"
      #budget="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="!budget.isActive">
        <img src="assets/budgetIcon.png" class="img-responsive" alt="budget" />
      </em>
      <em class="fa fa-fw" *ngIf="budget.isActive">
        <img
          src="assets/budgetActicveIcon.svg"
          class="img-responsive"
          alt="budget"
        /> </em
      >&nbsp;
      <span>{{ "Budget" }}</span>
    </a>
    <a
      class="list-group-item"
      [ngClass]="{
        emptyBackground: rolesEmptyFlag == true
      }"
      [routerLink]="['/viewroles']"
      [routerLinkActive]="['active']"
      #roles="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="roles.isActive">
        <img
          src="assets/roles_active.svg"
          class="img-responsive"
          alt="roles&Responsibility"
        />
      </em>
      <em class="fa fa-fw" *ngIf="!roles.isActive">
        <img
          src="assets/roles.svg"
          class="img-responsive"
          alt="roles&Responsibility"
        /> </em
      >&nbsp;
      <span>{{ "Roles & Responsibility" }}</span>
    </a>
    <a
      class="list-group-item"
      [ngClass]="{
        emptyBackground: riskEmptyFlag == true
      }"
      [routerLink]="['/risk']"
      [routerLinkActive]="['active']"
      #risk="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="risk.isActive">
        <img src="assets/risk_active.svg" class="img-responsive" alt="risk" />
      </em>
      <em class="fa fa-fw" *ngIf="!risk.isActive">
        <img src="assets/risk.svg" class="img-responsive" alt="risk" /> </em
      >&nbsp;
      <span>{{ "Risks/Obstacles" }}</span>
    </a>
    <a
      class="list-group-item"
      [ngClass]="{
        emptyBackground: kpiEmptyFlag == true
      }"
      [routerLink]="['/kpi']"
      [routerLinkActive]="['active']"
      #kpi="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="kpi.isActive">
        <img src="assets/kpi_active.svg" class="img-responsive" alt="kpi" />
      </em>
      <em class="fa fa-fw" *ngIf="!kpi.isActive">
        <img src="assets/kpi.svg" class="img-responsive" alt="kpi" /> </em
      >&nbsp;
      <span>{{ "KPIs" }}</span>
    </a>

    <a
      class="list-group-item"
      [routerLink]="['/activity-schedule']"
      [routerLinkActive]="['active']"
      #activity="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="activity.isActive">
        <img
          src="assets/activity_active.svg"
          class="img-responsive"
          alt="activitySchedule"
        />
      </em>
      <em class="fa fa-fw" *ngIf="!activity.isActive">
        <img
          src="assets/activity.svg"
          class="img-responsive"
          alt="activitySchedule"
        /> </em
      >&nbsp;
      <span>{{ "Activity Schedule" }}</span>
    </a>
    <a
      class="list-group-item"
      [routerLink]="['/communication-matrix']"
      [routerLinkActive]="['active']"
      #communication="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="communication.isActive">
        <img
          src="assets/communicationmatrix_active.svg"
          class="img-responsive"
          alt="communicationMatrix"
        />
      </em>
      <em class="fa fa-fw" *ngIf="!communication.isActive">
        <img
          src="assets/communication.svg"
          class="img-responsive"
          alt="communicationMatrix"
        /> </em
      >&nbsp;
      <span>{{ "Meetings" }}</span>
    </a>
    <a
      class="list-group-item"
      [routerLink]="['/reports']"
      [routerLinkActive]="['active']"
      *RoleShow="['SIPM-ProjectLead', 'SIPM-Admin']"
      #reports="routerLinkActive"
    >
      <em class="fa fa-fw" *ngIf="reports.isActive">
        <img
          style="width: 27px"
          src="assets/reports-icon.svg"
          class="img-responsive"
          alt="reports"
        />
      </em>
      <em class="fa fa-fw" *ngIf="!reports.isActive">
        <img
          style="width: 27px"
          src="assets/reports-icon.svg"
          class="img-responsive"
          alt="reports"
        /> </em
      >&nbsp;
      <span>{{ "Reports" }}</span>
    </a>

    <a class="list-group-item mobile-only" (click)="logout()">
      <em class="fa fa-fw">
        <img src="assets/logout.svg" class="img-responsive" alt="logout" /> </em
      >&nbsp;
      <span>{{ "Logout" }}</span>
    </a>
  </div>
</nav>
