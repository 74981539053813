<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title align-self-center">Projects</h5>
  </div>
  <div class="modal-body">
    <div class="table-title">
      <div class="w-40 d-inline-block">Project Code</div>
      <div class="w-30 d-inline-block">Project Name</div>
      <div class="w-30 d-inline-block text-right">Program Lead</div>
    </div>

    <div class="dataTable-filter clearfix">
      <div id="search-input">
        <div class="input-group">
          <input
            type="text"
            class="filter-text form-control"
            placeholder="Search"
            id="searchInput"
            [(ngModel)]="searchTerm"
          />
          <span class="input-group-btn">
            <button class="btn btn-danger noFocus" type="button">
              <img
                class="img-responsive"
                src="assets/search.svg"
                alt="search"
              />
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="table-responsive table-wrapper mb-3 scrollable">
      <table
        aria-describedby="projectList"
        class="table table-striped table-sm table-container create-wrapper"
      >
        <tbody class="collapse show" id="collapseExample">
          <th id="hdr" class="hidden"></th>
          <td id="hdr_project" *ngIf="storedData.length === 0">
            No Project Assign
          </td>
          <tr
            class="cursor-pointer"
            *ngFor="
              let i of storedData
                | filter: searchTerm
                | orderBy: 'wbsProjectCode':reverse
            "
            id="selectedRow"
            [ngClass]="{
              highlight: selectedRowIndex?.projectName === i.projectName
            }"
            (click)="highlight(i)"
          >
            <td class="w-40">{{ i.wbsProjectCode }}</td>
            <td class="w-30">{{ i.projectName }}</td>
            <td class="w-30 text-right">{{ i.projectLead }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn red-outline-button button-rounded"
      (click)="closeModal()"
    >
      Cancel
    </button>
    <ng-container *RoleShow="['SIPM-Admin', 'SIPM-ProjectLead']">
      <button
        *ngIf="!isPreference"
        type="button"
        (click)="navigateProject()"
        class="btn red-button button-rounded"
      >
        Create Project
      </button>
    </ng-container>

    <button
      type="button"
      (click)="submit(selectedRowIndex)"
      class="btn red-button button-rounded"
    >
      Switch
    </button>
  </div>
</div>
