<nav class="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item show-pointer" routerLink="/">Home</li>
    <li
      class="breadcrumb-item"
      [routerLink]="navlabelUrl"
      id="primaryclass"
      [ngClass]="{
        'show-pointer': pagelabel && navlabel !== 'Master'
      }"
    >
      {{ navlabel }}
    </li>
    <li
      class="breadcrumb-item"
      [ngClass]="{ active: navlabel && 'pagelabel' }"
      *ngIf="this.sc"
    >
      {{ pagelabel }}
    </li>
  </ol>
</nav>

<div class="help">
  <span class="helpButton" (click)="openHelpModal()"
    >Help <img src="../../../assets/Help.svg" alt="help_icon"
  /></span>
</div>
