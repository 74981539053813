import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly httpClient: HttpClient) {}

  private readonly currentPrj$ = new BehaviorSubject<any>({
    projectName: '',
    projectCode: '',
    from: '',
  });
  currentPrj = this.currentPrj$.asObservable();

  //calling the graph api to check the validity of the emails.
  checkUserbyEmail(emailList: any) {
    return this.httpClient
      .post(
        `${environment.baseUrl}${endpoint.USERs}?action=getMatchedUsers`,
        emailList
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getImage(user: any) {
    return this.httpClient
      .post(`${environment.baseUrl}${endpoint.USER}?action=getUsersImage`, user)
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  getPreference() {
    return this.httpClient.get(environment.baseUrl + endpoint.USER).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  updatePreference(selectedProject: any) {
    return this.httpClient
      .put(environment.baseUrl + endpoint.USER, selectedProject)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  updateProfilePic(imageUrl: any) {
    return this.httpClient.post(
      `${environment.baseUrl}${endpoint.USER}?action=uploadImage`,
      imageUrl
    );
  }
  setCurrentPrj(prj: any) {
    this.currentPrj$.next(prj);
  }
}
