import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  isActive: boolean;
  collapsed: boolean;
  showMenu: string;
  pushRightClass: string;
  budgetEmptyFlag = false;
  rolesEmptyFlag = false;
  riskEmptyFlag = false;
  kpiEmptyFlag = false;
  @Output() collapsedEvent = new EventEmitter<boolean>();

  constructor(
    public router: Router,
    private readonly projectService: ProjectService,
    private readonly authService: MsalService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this.isActive = false;
    this.collapsed = false;
    this.showMenu = '';
    this.pushRightClass = 'open';
    this.invokeGetProjectDetail();
  }
  invokeGetProjectDetail() {
    this.projectService.prjObj.subscribe((res) => {
      this.budgetEmptyFlag = false;
      this.rolesEmptyFlag = false;
      this.riskEmptyFlag = false;
      this.kpiEmptyFlag = false;

      if (res && res.data) {
        this.invokeProjectByCode();
      } else {
        if (!res.taskFlag || res.taskFlag === false) {
          this.rolesEmptyFlag = true;
        }
        if (!res.budgetFlag || res.budgetFlag === false) {
          this.budgetEmptyFlag = true;
        }
        if (!res.risk || res.risk.length < 1) {
          this.riskEmptyFlag = true;
        }
        if (!res.kpi || res.kpi.length < 1) {
          this.kpiEmptyFlag = true;
        }
      }
    });
  }
  invokeProjectByCode() {
    const projectCode = sessionStorage.getItem('currentPrj');
    this.projectService.getProjectbyCode(projectCode).subscribe((res) => {
      this.projectService.setSelectedPrj(res);
    });
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.collapsedEvent.emit(this.collapsed);
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }
  logout() {
    sessionStorage.clear();
    this.authService.logoutRedirect();
  }
}
