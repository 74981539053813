import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SwitchProjectDialogComponent } from './switch-project-dialog/switch-project-dialog.component';
import { LayoutComponent } from './layout.component';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDropdownModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatar';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { SharedModule } from '../../shared/shared.module';
import { ImageUploadComponent } from './header/image-upload/image-upload.component';
import { NotificationModal } from '../../features/notification-modal/notification-modal.component';
import {
  CustomAdapter,
  DateParserFormatterService,
} from '../services/dateParserFormatter/date-parser-formatter.service';

const avatarColors = [
  '#20c997',
  '#57a3e6',
  '#ff96c6',
  '#f0a856',
  '#38e0bf',
  '#ca5dd4',
];

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SideBarComponent,
    SwitchProjectDialogComponent,
    ImageUploadComponent,
    NotificationModal,
  ],
  entryComponents: [SwitchProjectDialogComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModule,
    FormsModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    LayoutRoutingModule,
    AvatarModule.forRoot({
      colors: avatarColors,
    }),
    SharedModule,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterService },
  ],
})
export class LayoutModule {}
