<nav class="navbar navbar-expand-lg fixed-top" id="top-nav">
  <div style="width: 100%" class="collapse navbar-collapse">
    <div>
      <span class="project-title" id="title" style="line-break: anywhere">
        {{ projectName }}
      </span>
    </div>
  </div>

  <div style="width: 67%" class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="d-flex mr-4">
        <div *ngIf="milestoneArray && milestoneArray.length > 0" class="icon mt-4 mr-3">
          <em
            *ngIf="!hide"
            class="fa fa-bell bell-alert fa-2x"
            (click)="showNotification()"
          ></em>
          <em
            *ngIf="hide"
            class="fa fa-bell fa-2x"
            (click)="showNotification()"
          ></em>
        </div>
        <button
          style="font-size: 18px"
          class="btn red-button align-self-center switch-button"
          (click)="openSwitchModal()"
        >
          Switch Project
        </button>
      </li>
      <li class="d-flex mr-4" *ngIf="router.url == '/dashboard'">
        <button
          style="font-size: 18px"
          class="btn red-button align-self-center switch-button"
          (click)="exportPdf()"
        >
          Export
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="17"
            fill="currentColor"
            class="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
            />
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
            />
          </svg>
        </button>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a style="display: flex" class="nav-user">
          <span class="mr-3 No-Pointer"> {{ currentUser.name }} </span>

          <ngx-avatar
            [src]="userImage"
            [value]="intials"
            (click)="imageUpload()"
            borderColor="gray"
            size="50"
            round="true"
            class="ngx-new-avatar"
            alt="profile image"
          ></ngx-avatar>
          <img
            ngbDropdownToggle
            class="dropdown-toggle-headermenu-img"
            src="../../../../assets/menu.png"
            alt="menu"
          />
        </a>

        <div
          class="dropdown-menu-right dropdown-action text-right"
          ngbDropdownMenu
        >
          <div class="arrow-2 arrow-up"></div>
          <div class="arrow-up"></div>
          <a class="dropdown-item show-pointer" (click)="logout()">
            {{ "Logout" }}
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div id="menuToggle">
    <ul class="navbar-nav ml-auto menu-navbar">
      <li class="d-flex mr-4">
        <button
          style="font-size: 18px"
          class="btn red-button align-self-center switch-button"
          (click)="openSwitchModal()"
        >
          Switch Project
        </button>
      </li>
      <li class="d-flex mr-4" *ngIf="router.url == '/dashboard'">
        <button
          style="font-size: 18px"
          class="btn red-button align-self-center switch-button"
          (click)="exportPdf()"
        >
          Export
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="17"
            fill="currentColor"
            class="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
            />
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
            />
          </svg>
        </button>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-user nav-user-ipad" href="javascript:void(0)">
          <ngx-avatar
            [src]="userImage"
            [value]="intials"
            size="50"
            borderColor="gray"
            class="ngx-new-avatar"
            alt="profile image"
            placement="bottom"
            ngbTooltip="{{ currentUser.name }}"
          ></ngx-avatar>
        </a>
      </li>
    </ul>
    <div style="display: flex">
      <a (click)="toggleSidebar()">
        <img src="../../../../assets/menu.svg" class="menu-icon" alt="menu" />
      </a>

      <p class="project-title ellipse-content">
        {{ projectName }}
      </p>
    </div>
  </div>
</nav>
