import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../../../shared/alert/alert.component';
import { message } from '../../../shared/config/message.config';
import { ModalStateService } from '../../../shared/services/modal-state.service';
import { ModalConfig } from '../../services/models/modal-config';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-switch-project-dialog',
  templateUrl: './switch-project-dialog.component.html',
  styleUrls: ['./switch-project-dialog.component.scss'],
})
export class SwitchProjectDialogComponent implements OnInit {
  @Input() storedData: any;
  @Input() isPreference: boolean;
  @Input() selectedRowIndex: any;
  searchTerm: string;
  reverse = false;
  highlightedRow: HTMLElement;
  errorMessage: string;
  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalState: ModalStateService,
    private readonly _projectSrv: ProjectService,
    private readonly _modalService: NgbModal,
    private readonly _router: Router
  ) {}

  ngOnInit() {}

  highlight(selectedRowIndex) {
    this.selectedRowIndex = selectedRowIndex;
  }
  submit(value) {
    sessionStorage.removeItem('currentProjectDetails');
    sessionStorage.removeItem('ganttChartData');
    this.activeModal.close(this.selectedRowIndex);
  }
  closeModal() {
    if (this.isPreference) {
      this.activeModal.close('Modal Closed');
    } else {
      const modalRef = this._modalService.open(AlertComponent, {
        centered: true,
      });
      const configs = new ModalConfig();
      configs.modalHeader = 'Alert';
      configs.modalBody = message.NO_PREFERENCE;
      configs.cancelBtn = false;
      configs.cancelBtnName = '';
      configs.saveBtn = true;
      configs.saveBtnName = 'OK';
      configs.deleteBtn = false;
      this._modalState.changeConfig(configs);
      modalRef.result.then((result) => {
        if (result) {
          modalRef.close();
        }
      });
    }
  }
  navigateProject() {
    this._projectSrv.setSwitchCreate({ showFlag: true });
    this._router.navigate(['/master/create-project']);
    this.activeModal.close('Modal Closed');
  }
}
