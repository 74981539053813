import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HelpComponent } from '../help/help.component';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {
  @Input() navlabel: any;
  @Input() navlabelUrl: any;
  @Input() pagelabel: any;

  sc = false;
  secondary: any;
  constructor(private readonly modalService: NgbModal) {}

  ngOnInit() {
    var getid = document.getElementById('primaryclass');
    if (this.navlabel) {
      this.sc = false;
      getid.classList.add('active');
    }
    if (this.navlabel && this.pagelabel) {
      this.sc = true;
      getid.classList.remove('active');
    }
  }

  openHelpModal() {
    const modalRef = this.modalService.open(HelpComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.label = this.pagelabel
      ? this.pagelabel
      : this.navlabel;
  }
}
