import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalStateService } from '../../../../shared/services/modal-state.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  url = '';
  initials: any;
  uploadFlag = false;
  errorMessage: string;
  constructor(
    private readonly modalState: ModalStateService,
    public modal: NgbActiveModal,
    private readonly modalService: NgbModal,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.modalState.config.subscribe((result) => {
      if (result.modalBody.length > 5) {
        this.url = result.modalBody;
      } else {
        this.initials = result.modalBody;
      }
    });
  }
  onSelectImage(event): void {
    this.uploadFlag = false;
    this.errorMessage = undefined;
    const reader = new FileReader();

    if (event.target.files.length > 0 && event.target.files[0].size < 200000) {
      document.getElementById('imageLabel').innerText =
        event.target.files[0].name;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (img: any) => {
        this.url = img.target.result;
        this.uploadFlag = true;
      };
    } else {
      this.errorMessage = 'Uploaded image size is more than 300 KB.';
    }
  }
  uploadImage(): void {
    this.uploadFlag = false;
    const reqData = {
      imageInBase64: this.url.split(',')[1],
    };
    this.userService.updateProfilePic(reqData).subscribe((res: any) => {
      if (res.status.code === '200') {
        sessionStorage.setItem('profilImage', this.url);
        this.uploadFlag = true;
        this.modal.close(true);
      } else {
        this.errorMessage = res.status.messages[0].description;
      }
    });
  }
}
