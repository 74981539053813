import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Project } from './project.model';
import { map } from 'rxjs/internal/operators/map';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

/*Created by Avisikta Nandan*/
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projectData: any[];
  deletedProject: Project;
  project: any[];

  private readonly isDelete$ = new BehaviorSubject<any>({
    showFlag: false,
    organizationName: '',
  });
  isDelete = this.isDelete$.asObservable();

  private readonly isReactivate$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  isReactivate = this.isReactivate$.asObservable();

  private readonly isClosed$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  isClosed = this.isClosed$.asObservable();

  private readonly isCreate$ = new BehaviorSubject<any>({
    showFlag: false,
    organizationName: '',
  });
  isCreate = this.isCreate$.asObservable();

  private readonly isModified$ = new BehaviorSubject<any>({
    showFlag: false,
    organizationName: '',
  });
  isModified = this.isModified$.asObservable();

  private readonly prjObj$ = new BehaviorSubject<any>({
    data: [],
  });
  prjObj = this.prjObj$.asObservable();

  private readonly projList$ = new BehaviorSubject<any>(false);
  projList = this.projList$.asObservable();

  private readonly prjDetails$ = new BehaviorSubject<any>({
    showFlag: false,
    result: [],
  });
  prjDetails = this.prjDetails$.asObservable();

  private readonly switchCreate$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  switchCreate = this.switchCreate$.asObservable();

  private readonly switchProject$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  switchProject = this.switchProject$.asObservable();

  private readonly addNewChild$ = new BehaviorSubject<any>({
    showFlag: false,
  });
  addNewChild = this.addNewChild$.asObservable();

  private readonly kpiData = new BehaviorSubject<any>(null);
  kpiDataList = this.kpiData.asObservable();

  private readonly riskData = new BehaviorSubject<any>(null);
  riskDataList = this.riskData.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  //This Function fetch the Project Data
  getProjectData(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + endpoint.PROJECT).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getProjectbyCode(projectCode: string): Observable<any> {
    return this.httpClient
      .get(
        `${environment.baseUrl}${endpoint.PROJECT}?projectCode=${projectCode}`
      )
      .pipe(
        map((res: any) => {
          if (res.status.messages[0].code === 'SIPM_PRO_ERR_052') {
            return 'SIPM_PRO_ERR_052';
          }
          this.setSwitchCreate(false);
          return res.data.project;
        })
      );
  }

  getProjectbyClassification(classification1: string): Observable<any> {
    return this.httpClient
      .get(
        `${environment.baseUrl}${endpoint.PROJECT}?Classification=${classification1}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  // This function is to  create a Project
  createProject(createRequest: any): Observable<any> {
    return this.httpClient
      .post(environment.baseUrl + endpoint.PROJECT, createRequest)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  //save project
  submitProject(submitRequest: any): Observable<any> {
    return this.httpClient
      .put(
        `${environment.baseUrl}${endpoint.PROJECT}?action=update&submit=true`,
        submitRequest
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateProject(updateRequest: any): Observable<any> {
    return this.httpClient
      .put(
        `${environment.baseUrl}${endpoint.PROJECT}?action=update`,
        updateRequest
      )
      .pipe(
        map((res: any) => {
          return res.status;
        })
      );
  }
  //inactive project
  inactiveProject(inactive: any): Observable<any> {
    return this.httpClient
      .put(`${environment.baseUrl}${endpoint.PROJECT}?action=delete`, inactive)
      .pipe(
        map((res: any) => {
          return res.status.messages[0].code;
        })
      );
  }
  //reactivate Project
  reactivateProject(reactivate: any): Observable<any> {
    return this.httpClient
      .put(
        `${environment.baseUrl}${endpoint.PROJECT}?action=reactivate&submit=true`,
        reactivate
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  //dependecy check for user
  userDependencyPrj(emailId: string, prjCode: string) {
    return this.httpClient
      .get(
        `${environment.baseUrl}${endpoint.PROJECT}?emailId=${emailId}&action=dependency-check&projectCode=${prjCode}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  fetchProjectData(): any[] {
    return this.projectData;
  }
  setProjectData(data) {
    this.projectData = data;
  }
  setSelectedPrj(data) {
    this.prjObj$.next(data);
  }
  setUpdatedProjectList(flag) {
    this.projList$.next(flag);
  }
  setCreatePrj(data) {
    this.prjDetails$.next(data);
  }

  setSwitchCreate(flag) {
    this.switchCreate$.next(flag);
  }

  setSwitchProject(flag) {
    this.switchProject$.next(flag);
  }

  setAddNewChild(flag) {
    this.addNewChild$.next(flag);
  }

  setCreate(value: any) {
    this.isCreate$.next(value);
  }
  setDelete(value: any) {
    this.isDelete$.next(value);
  }
  setModify(value: any) {
    this.isModified$.next(value);
  }
  setReactive(value: any) {
    this.isReactivate$.next(value);
  }
  setClosure(value: any) {
    this.isClosed$.next(value);
  }

  setKpiData(data: any = []) {
    this.kpiData.next(data);
  }

  setRiskData(data: any = []) {
    this.riskData.next(data);
  }
  // coreFour from list to indiviual data
  contructListData(data: any) {
    var project = data;
    data.forEach((e, i) => {
      if (e.coreFour) {
        project[i]['coreFour1'] = e.coreFour[0].check;
        project[i]['coreFour2'] = e.coreFour[1].check;
        project[i]['coreFour3'] = e.coreFour[2].check;
        project[i]['coreFour4'] = e.coreFour[3].check;
      } else {
        project[i]['coreFour1'] = false;
        project[i]['coreFour2'] = false;
        project[i]['coreFour3'] = false;
        project[i]['coreFour4'] = false;
      }
    });

    return project;
  }
  getProjectDependency(pjCd): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.baseUrl}${endpoint.PROJECT}?action=getParentProject&projectCode=${pjCd}`
    );
  }

  deleteProject(pjCd): Observable<any[]> {
    return this.httpClient
      .put(
        `${environment.baseUrl}${endpoint.PROJECT}?action=deleteProject`,
        pjCd
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
