import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private readonly http: HttpClient) {}

  public getConstants(): Observable<any> {
    return this.http.get(environment.baseUrl + endpoint.MISC);
  }
}
