<div class="modal-content">
  <div class="modal-header">
    <h5 class="text-center modal-title">Upload Image</h5>
    <img
      class="cross_icon cursor-pointer"
      src="../../../../../assets/cross_icon.svg"
      alt="cross icon"
      (click)="modal.close(false)"
    />
  </div>

  <div class="modal-body">
    <div class="alert alert-success failure" role="alert" *ngIf="errorMessage">
      <em class="fa">
        <img src="assets/red-success.svg" alt="duplicate icon" /> </em
      >  {{errorMessage}}
    </div>
    <div class="center">
      <ngx-avatar
        [src]="url"
        borderColor="gray"
        [value]="initials"
        size="150"
        class="ngx-new-avatar"
        alt="profile image"
      ></ngx-avatar>
    </div>

    <div class="custom-file mb-3">
      <input
        type="file"
        id="imgFile"
        (change)="onSelectImage($event)"
        accept="image/png, image/jpeg"
        class="custom-file-input"
      />
      <label class="custom-file-label" id="imageLabel" for="customFile"
        >Choose file</label
      >
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="modal.close(false)"
      class="btn red-outline-button button-rounded"
    >
      Cancel
    </button>
    <button
      type="submit"
      [disabled]="!uploadFlag"
      (click)="uploadImage()"
      class="btn default-button button-rounded float-right"
    >
      Submit
    </button>
  </div>
</div>
