export class ModalConfig {
    modalHeader: string;
    modalBody: string;
    cancelBtn: boolean;
    cancelBtnName: string;
    deleteBtn: boolean;
    saveBtn: boolean;
    saveBtnName: string;
    fromCopyModal: boolean;
    childDependencyData:any;
    parentDependencyData: any;
    message: string;
    dependentEntities: any;
}
