import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../../features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'master',
        loadChildren: () =>
          import('../../features/master/master.module').then(
            (m) => m.MasterModule
          ),
      },

      {
        path: 'kpi',
        loadChildren: () =>
          import('../../features/kpi/kpi.module').then((m) => m.KpiModule),
      },
      {
        path: 'risk',
        loadChildren: () =>
          import('../../features/risk/risk.module').then((m) => m.RiskModule),
      },
      {
        path: 'activity-schedule',
        loadChildren: () =>
          import(
            '../../features/activity-schedule/activity-schedule.module'
          ).then((m) => m.ActivityScheduleModule),
      },
      {
        path: 'budget',
        loadChildren: () =>
          import('../../features/budget/budget.module').then(
            (m) => m.BudgetModule
          ),
      },
      {
        path: 'viewroles',
        loadChildren: () =>
          import(
            '../../features/roles-responsiblity/roles-responsiblity.module'
          ).then((m) => m.RolesResponsiblityModule),
      },
      {
        path: 'orgdatasheet',
        loadChildren: () =>
          import('../../features/org-datasheet/org-datasheet.module').then(
            (m) => m.OrgDatasheetModule
          ),
      },
      {
        path: 'communication-matrix',
        loadChildren: () =>
          import(
            '../../features/communication-matrix/communication-matrix.module'
          ).then((m) => m.CommunicationMatrixModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('../../features/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
