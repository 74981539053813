<div class="clearfix">
    <div class="dataTables_info float-left">List {{si}}-{{ei}} of {{totalitems}}</div>
    <div class="dataTable_paginate float-right">
      <nav aria-label="Page navigation example">
  
        <ul class="pagination pagination-sm" *ngIf="pager.pages && pager.pages.length" 
        class="pagination"  id="changeColor">
  
          <li class="page-item"[ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="setPage(1)">
              <img class="page-action" src="assets/first.svg" alt="firstPage"/>
            </a> 
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link"  (click)="setPage(pager.currentPage - 1)" >
              <img class="page-action" src="assets/previous.svg" alt="previousPage"/>

            </a>
          </li>
  
         
  
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="setPage(page)">{{page}}</a>
          </li>
  
  
  
          <li class="page-item" class="disabled" *ngIf="(pager.totalPages - pager.startPage) > 4 ">
            <a class="page-link" (click)="setPage(pager.startPage + 4)" >...</a>
          </li>
  
          <li class="page-item" *ngIf="(pager.totalPages - pager.startPage) > 4">
            <a class="page-link " (click)="setPage(pager.totalPages)">{{lastSecondPage}} {{pager.totalPages}}</a>
          </li>
        
          <li class="page-item"[ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a  class="page-link"(click)="setPage(pager.currentPage + 1)">
              <img class="page-action" src="assets/next.svg" alt="nextPage"/>
            </a>
          </li>
          <li class="page-item"  [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="setPage(pager.totalPages)">
              <img class="page-action" src="assets/last.svg" alt="lastPage"/>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div> 