<div class="modal-content" id="enableModal">
    <div class="modal-header">
        <h5 class="text-center modal-title">Upcoming Milestones:</h5>
    </div>
    <div class="text-center modal-body">
        <div class="row cursorIcon mb-2" *ngFor="let x of milestoneArray,let i =index">
            <div class="col-2">{{i+1}}</div>
            <div class="col-6" (click)="moveTo(x)">{{x.taskName}}</div>
            <div class="col-4">{{x.startDate}}</div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn red-button button-rounded" (click)="activeModal.close(true)">Close</button>
    </div>
</div>