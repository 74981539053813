export const endpoint = {
  GROUP: '/master/group',
  PHASE: '/master/phase',
  ORGANIZATION: '/master/organization',
  PROJECT: '/project',
  MISC: '/master/misc',
  USER: '/master/user-profile',
  USERs: '/master/users',
  Budget: '/project-misc/budget',
  Expense: '/project-misc/expense',
  MAJORTASK: '/project-task/major-task',
  TASK: '/project-task/task',
  Risk: '/project-misc/risk',
  Reminder: '/project-task/reminder',
  COM_MATRIX: '/communication-matrix',
};
