import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Directive({
  selector: '[RoleShow]',
})
export class RoleShowDirective {
  allowedRoles: string[];
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authService: MsalService
  ) {}

  @Input()
  set RoleShow(allowedRoles: string[]) {
    this.allowedRoles = allowedRoles;
    if (
      !this.allowedRoles ||
      this.allowedRoles.length === 0 ||
      !(this.authService.instance.getAllAccounts()[0].idTokenClaims as any)
        .roles[0]
    ) {
      this.viewContainer.clear();
      return;
    }

    const allowed: boolean =
      (
        this.authService.instance.getAllAccounts()[0].idTokenClaims as any
      ).roles.filter((role) => this.allowedRoles.includes(role)).length > 0;

    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
