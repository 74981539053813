import {
  MsalBroadcastService,
  MsalCustomNavigationClient,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'SocialInnovationPartnerManagement';
  isIframe = false;
  loginDisplay = false;
  red = '#F00';
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly router: Router,
    private readonly location: Location,
    private readonly idle: Idle
  ) {
    // sets an idle timeout of 60 Minutes, for testing purposes.
    this.idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 3605 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {});
    this.idle.onTimeout.subscribe(() => {
      sessionStorage.clear();
      this.authService.logoutRedirect();
    });
    this.reset();
    const customNavigationClient = new MsalCustomNavigationClient(
      authService,
      this.router,
      this.location
    );
    this.authService.instance.setNavigationClient(customNavigationClient);
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
