import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../services/layout/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  collapsedSideBar: boolean;
  role: any;
  constructor(private readonly _layoutService: LayoutService) {}

  ngOnInit(): void {
    const miscToyotaDivDetails = sessionStorage.getItem('MISC#TOYOTA_DIVISION');
    if (miscToyotaDivDetails === null || miscToyotaDivDetails.length === 0) {
      //checking whether data is existing in local storage or not, based api call is invoked
      this.invokeMiscDetails();
    }
  }
  hideSideBar() {
    if (
      document.getElementsByTagName('body')[0].classList.contains('open') ===
      true
    ) {
      document.getElementsByTagName('body')[0].classList.remove('open');
    }
  }
  reciedvedCollapsed($event) {
    this.collapsedSideBar = $event;
  }

  invokeMiscDetails() {
    this._layoutService.getConstants().subscribe((response) => {
      const miscMaster =
        response.data.miscMasters && response.data.miscMasters.length > 0
          ? response.data.miscMasters
          : [];
      miscMaster.forEach((element) => {
        sessionStorage.setItem(element.id, element.data);
      });
      sessionStorage.setItem('GROUP', JSON.stringify(response.data.groups));
      sessionStorage.setItem('PHASE', JSON.stringify(response.data.phases));
    });
  }
}
