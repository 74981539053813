import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../core/services/models/modal-config';
import { ModalStateService } from '../services/modal-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() id: number;
  @Input() operation: string;
  deleteData: any;
  operations: any;
  modalBody: string;
  noBtn: boolean;
  yesBtn: boolean;
  noBtnName: string;
  yesBtnName: string;
  fromCopy: boolean;
  createdData = { value: 'Create Operation' };
  editData = { value: 'Edit Operation' };
  projectName: string;
  ProjectCode: any;
  project = { prjctName: '', PrjctCode: '' };
  prjclist = [];
  childDependency: any = [];
  parentDependencyData: any = []
  childDep: any =[];
  message: string;
  dependentEntities: any;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly modalState: ModalStateService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.modalState.config.subscribe((result: ModalConfig) => {
      this.operation = result.modalHeader;
      this.modalBody = result.modalBody;
      this.message = result.message;
      this.noBtn = result.cancelBtn;
      this.noBtnName = result.cancelBtnName;
      this.yesBtn = result.saveBtn;
      this.yesBtnName = result.saveBtnName;
      this.fromCopy = result.fromCopyModal;
      this.childDependency = result.childDependencyData;
      this.parentDependencyData = result.parentDependencyData;
      this.dependentEntities = result.dependentEntities;
    });
    
    this.childDependency?.forEach(x=>{
      if(x.value.length > 0){
        this.childDep.push(x);
      }
    });
  }
  onClick() {
    if (this.fromCopy) {
      this.project = {
        prjctName: this.projectName,
        PrjctCode: this.ProjectCode,
      };
      this.prjclist.push(this.project);
      sessionStorage.setItem('projectDetails', JSON.stringify(this.prjclist));
      this.router.navigate(['/master/project']);
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.project = { prjctName: '', PrjctCode: '' };
  }
}

function postRequest(arg0: string, postRequest: any) {
  throw new Error('Function not implemented.');
}


