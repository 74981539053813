<div class="input-group">
  <input
    style="border: 1px solid #58595b"
    [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control"
    (blur)="inputBlur($event)"
    placeholder="MM/DD/YYYY hh:mm AM/PM"
    [ngModel]="dateString | date: inputDatetimeFormat"
    (change)="onInputChange($event)"
    [disabled]="disabled"
  />

  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary calendar"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      type="button"
    >
      <img src="../../../../../assets/calendar-1.png" alt="calender" />
    </button>
  </div>
</div>

<ng-template #calendarContent>
  <div>
    <div *ngIf="!showTimePickerToggle">
      <ngb-datepicker
        id="dp"
        #dp
        name="datepicker"
        [ngModel]="datetime"
        (ngModelChange)="onDateChange($event, dp)"
      ></ngb-datepicker>
      <button
        class="btn btn-block btn-outline-secondary"
        [disabled]="!datetime?.day"
        [ngbPopover]="timePickerContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <img src="../../../assets/clock_white.svg" alt="clock" />
      </button>
    </div>
    <div *ngIf="showTimePickerToggle">
      <button
        class="btn btn-block btn-outline-secondary"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <img src="../../../../../assets/calendar-1.png" alt="calender" />
      </button>
      <div class="mt-auto">
        <ngb-timepicker
          #tp
          name="timepicker"
          [ngModel]="datetime"
          (ngModelChange)="onTimeChange($event)"
          [hourStep]="hourStep"
          [meridian]="true"
          [minuteStep]="minuteStep"
        >
        </ngb-timepicker>
      </div>
    </div>
  </div>
</ng-template>
