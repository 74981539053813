import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  @Input() label: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  close(): void {
    this.activeModal.close();
  }
}
