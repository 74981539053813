import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { endpoint } from '../../../shared/config/endpoint.config';

@Injectable({
  providedIn: 'root',
})
export class ActivityScheduleService {
  private readonly dataShare = new BehaviorSubject<any>({
    action: '',
    pvalue: {},
    cvalue: {},
    type: '',
  });
  config = this.dataShare.asObservable();

  private readonly message$ = new BehaviorSubject<any>({
    request: '',
    successOrFailureFlag: '',
    type: '',
    errorMsg: '',
    mtName: ''
  });
  message = this.message$.asObservable();
  public activityScheduleMessage = new BehaviorSubject('defalute');
  currentActivityMessage = this.activityScheduleMessage.asObservable();

  public projectData = new BehaviorSubject<any>(null);
  majorTaskData = this.projectData.asObservable();
  constructor(private readonly httpClient: HttpClient) {}

  getTaskData(pjCd): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.baseUrl}${endpoint.MAJORTASK}?projectCode=${pjCd}`
    );
  }

  createTask(task): Observable<any> {
    return this.httpClient.post(environment.baseUrl + endpoint.TASK, task);
  }

  editTask(task): Observable<any> {
    return this.httpClient.put(
      `${environment.baseUrl}${endpoint.TASK}?action=update`,
      task
    );
  }

  deleteTask(task): Observable<any> {
    return this.httpClient.put(
      `${environment.baseUrl}${endpoint.TASK}?action=delete`,
      task
    );
  }

  sendEmailReminder(task): Observable<any> {
    return this.httpClient.post(environment.baseUrl + endpoint.Reminder, task);
  }

  changeConfig(data) {
    this.dataShare.next(data);
  }

  changeMessage(data) {
    this.message$.next(data);
  }
  
  getProjectData(data){
    this.projectData.next(data)
  }


}
