export const message = {
  //common
  MIN_SEARCH: 'Minimum character require to search a record is 3',
  MAX_SEARCH: 'Maximum character allowed to search is 20 character',
  CANCEL: 'Do you want to cancel?',
  DELETE: 'Do you want to delete the record?',
  ALL_FIELD: 'All Fields Required',
  CORE_4: 'Please select Core 4',
  //group
  DUPLICATE_GRP_MSG: 'Duplicate Group Name',
  //partner
  DELETE_PARTNER: 'Do you want to delete the partner record?',
  //project
  PROJECT_REQ: 'Project Code is required',
  TOYOTA_ORG: 'SOCIAL INNOVATION',
  CORE1: 'Diverse workforce pipeline and talent',
  CORE2: 'Transition to mobility company',
  CORE3: 'Inclusive company culture',
  CORE4: 'Disparities in communities',
  INACTIVE_MSG: 'Do you want to Inactivate this Project?',
  //communication-matrix
  DELETE_ACTION_ITEM: 'Do you want to delete this Action Item?',
  DELETE_NXT_MIN: 'Do you want to delete this Next Meeting?',
  //switch-project
  NO_PREFERENCE:
    'Please SELECT a project or CREATE a new project to access the SI 3PM TOOL.',
  DELETE_MAJORTASK: 'Do you want to delete the record along with the subtasks?',
};
