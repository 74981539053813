<div class="modal-content">
  <div class="modal-header">
    <h5 class="text-center modal-title">Event</h5>
    <img
      class="cross_icon cursor-pointer"
      src="../../../assets/cross_icon.svg"
      alt="close"
      (click)="close()"
      alt="close icon"
    />
  </div>
  <div class="modal-body animInput">
    <div class="alert alert-failure failure" role="alert" *ngIf="duplicateFlag">
      <em class="fa"> <img src="assets/red-success.svg" alt="failure" /> </em>
      Duplicate record
    </div>
    <div class="alert alert-success success" role="alert" *ngIf="editMessage">
      <em class="fa"> <img src="assets/success.svg" alt="modified" /> </em>
      <span style="margin-left: 7px"> Task is modified successfully</span>
    </div>
    <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
      <div class="form-row" *ngIf="showTask">
        <div class="mb-2 mx-0 row" *ngIf="taskType != 'majorTask'">
          <div class="col-4 font-weight-bold">MajorTask Name</div>
          <div class="col-4 font-weight-bold">MajorTask Start Date</div>
          <div class="col-4 font-weight-bold">MajorTask End Date</div>

          <div class="col-4">
            {{ eventForm.get("majorTaskName").value }}
          </div>
          <div class="col-4">
            {{ eventForm.get("majorTaskStartDate").value }}
          </div>
          <div class="col-4">
            {{ eventForm.get("majorTaskEndDate").value }}
          </div>
        </div>

        <div class="form-group col-md-12 col-lg-12">
          <input
            type="text"
            formControlName="taskName"
            id="taskName"
            class="form-control"
            (blur)="changeTaskName()"
          />
          <label class="form-control-placeholder" for="taskName">Name</label>
          <span
            *ngIf="
              eventForm.get('taskName').hasError('minlength') &&
              (eventForm.get('taskName').dirty ||
                eventForm.get('taskName').touched)
            "
            class="mt-2 text-red"
          >
            <span *ngIf="taskType == 'task'">Task</span
            ><span *ngIf="taskType == 'checkpoint'">Checkpoint</span>
            <span *ngIf="taskType == 'milestone'">Milestone</span> Name is less
            than minimum allowed 3 characters
          </span>
          <span
            *ngIf="
              eventForm.get('taskName').hasError('required') &&
              (eventForm.get('taskName').dirty ||
                eventForm.get('taskName').touched)
            "
            class="mt-2 text-red"
          >
            <span *ngIf="taskType == 'task'">Task</span
            ><span *ngIf="taskType == 'checkpoint'">Checkpoint</span>
            <span *ngIf="taskType == 'milestone'">Milestone</span> Name is
            required
          </span>
          <span
            *ngIf="
              eventForm.get('taskName').hasError('maxlength') &&
              (eventForm.get('taskName').dirty ||
                eventForm.get('taskName').touched)
            "
            class="mt-2 text-red"
          >
            <span *ngIf="taskType == 'task'">Task</span
            ><span *ngIf="taskType == 'checkpoint'">Checkpoint</span>
            <span *ngIf="taskType == 'milestone'">Milestone</span> Name is
            exceeding the allowed 50 characters
          </span>
        </div>
        <div class="Activity-event-type form-group col-md-12 col-lg-6">
          <label class="form-control-placeholder date-placeholder" for="type"
            >Event Type</label
          >
          <ng-select
            id="type"
            class="custom"
            formControlName="type"
            (change)="changeEventType()"
          >
            <ng-option value="">Select event type</ng-option>
            <ng-option *ngFor="let a of taskTypeList" [value]="a">
              {{ a }}
            </ng-option>
          </ng-select>
          <span
            *ngIf="
              eventForm.get('type').hasError('required') &&
              (eventForm.get('type').dirty || eventForm.get('type').touched)
            "
            class="mt-2 text-red"
          >
            Event Type is required
          </span>
        </div>

        <!-- <div class="form-group col-md-12 col-lg-6">
          <input
            type="text"
            formControlName="group"
            id="group"
            class="form-control"
          />
          <label class="form-control-placeholder" for="group">Group</label>
        </div> -->
        <div class="Assigned-To-deopdown form-group col-md-12 col-lg-6">
          <label class="form-control-placeholder date-placeholder" for="leadNm"
            >Assigned To</label
          >
          <ng-select
            id="leadNm"
            class="custom"
            formControlName="leadNm"
            (change)="assignLeadObj()"
          >
            <ng-option *ngFor="let a of userList" [value]="a.emailId">{{
              a.firstName + " " + a.lastName
            }}</ng-option>
          </ng-select>
          <span
            *ngIf="
              eventForm.get('leadNm').hasError('required') &&
              (eventForm.get('leadNm').dirty || eventForm.get('leadNm').touched)
            "
            class="mt-2 text-red"
          >
            Assigned To is required
          </span>
        </div>
        <div class="form-group col-md-12 col-lg-12">
          <input
            type="text"
            formControlName="description"
            id="description"
            class="form-control"
          />
          <label class="form-control-placeholder" for="description"
            >Description</label
          >
          <span
            *ngIf="
              eventForm.get('description').hasError('minlength') &&
              (eventForm.get('description').dirty ||
                eventForm.get('description').touched)
            "
            class="mt-2 text-red"
          >
            Description is less than minimum allowed 3 characters
          </span>
          <span
            *ngIf="
              eventForm.get('description').hasError('required') &&
              (eventForm.get('description').dirty ||
                eventForm.get('description').touched)
            "
            class="mt-2 text-red"
          >
            Description is required
          </span>
          <span
            *ngIf="
              eventForm.get('description').hasError('maxlength') &&
              (eventForm.get('description').dirty ||
                eventForm.get('description').touched)
            "
            class="mt-2 text-red"
          >
            Description is exceeding the allowed 100 characters
          </span>
        </div>

        <div class="Activity-schedule-status form-group col-md-12 col-lg-6">
          <ng-select
            class="custom"
            id="status"
            formControlName="status"
            [searchable]="false"
            [items]="statusList"
            bindValue="name"
          >
            <ng-template ng-label-tmp let-item="item">
              <img height="20" width="20" alt="status" [src]="item.avatar" />
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="20" width="20" alt="status" [src]="item.avatar" />
            </ng-template>
          </ng-select>
          <label class="form-control-placeholder date-placeholder" for="status"
            >Status</label
          >
          <span
            *ngIf="
              eventForm.get('status').hasError('required') &&
              (eventForm.get('status').dirty || eventForm.get('status').touched)
            "
            class="mt-2 text-red"
          >
            Status is required
          </span>
        </div>
        <div class="form-group col-lg-6 col-md-12">
          <input
            type="text"
            formControlName="progress"
            id="progress"
            class="form-control"
            (blur)="addPercent()"
          />
          <label class="form-control-placeholder" for="progress"
            >Progress(%)</label
          >
          <span
            *ngIf="
              eventForm.get('progress').hasError('required') &&
              (eventForm.get('progress').dirty ||
                eventForm.get('progress').touched)
            "
            class="mt-2 text-red"
          >
            Progress is required
          </span>
          <span
            *ngIf="
              eventForm.get('progress').hasError('pattern') &&
              (eventForm.get('progress').dirty ||
                eventForm.get('progress').touched)
            "
            class="mt-2 text-red"
          >
            Only 0 to 100 (both inclusive) integers are allowed
          </span>
        </div>

        <div class="form-group col-lg-6 col-md-8">
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              formControlName="startDate"
              (click)="sd.toggle()"
              placeholder="Start Date(MM/DD/YYYY)"
              pattern="^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(1|2)\d{3}$"
              [maxDate]="maxDate"
              name="startDate"
              id="startDate"
              ngbDatepicker
              #sd="ngbDatepicker"
              (focusout)="checkStartDtValidation()"
            />
            <div class="datepicker input-group-append">
              <button
                style="
                  border: none;
                  outline: none;
                  background-color: transparent;
                  height: 45px;
                  margin-left: 20px;
                "
                (click)="sd.toggle()"
                (focusout)="checkStartDtValidation()"
                type="button"
              >
                <img
                  style="width: 100%"
                  src="../../../../assets/activityschedule.png"
                  alt="activityschedule"
                />
              </button>
            </div>
          </div>
          <span
            *ngIf="
              eventForm.get('startDate').hasError('required') &&
              (eventForm.get('startDate').dirty ||
                eventForm.get('startDate').touched)
            "
            class="mt-2 text-red"
          >
            Start Date is required
          </span>
          <span
            *ngIf="
              eventForm.get('startDate').hasError('pattern') &&
              (eventForm.get('startDate').dirty ||
                eventForm.get('startDate').touched)
            "
            class="mt-2 text-red"
          >
            Enter date in MM/DD/YYYY format
          </span>
          <span
            *ngIf="
              eventForm.get('startDate').hasError('lessThanMT') &&
              (eventForm.get('startDate').dirty ||
                eventForm.get('startDate').touched)
            "
            class="mt-2 text-red"
          >
            Date cannot be before the start date of the Project Phase
          </span>
          <span
            *ngIf="
              eventForm.get('startDate').hasError('greaterThanMT') &&
              (eventForm.get('startDate').dirty ||
                eventForm.get('startDate').touched)
            "
            class="mt-2 text-red"
          >
            Date cannot be after the end date of the Project Phase
          </span>
          <span
            *ngIf="
              eventForm.get('startDate').hasError('betweenMT') &&
              (eventForm.get('startDate').dirty ||
                eventForm.get('startDate').touched)
            "
            class="mt-2 text-red"
          >
            Date shall be within the range of Project Phase start and end date
          </span>
        </div>

        <ng-container *ngIf="showEndDate">
          <div class="form-group col-lg-6 col-md-8">
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                formControlName="endDate"
                (click)="ed.toggle()"
                placeholder="End Date(MM/DD/YYYY)"
                pattern="^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(1|2)\d{3}$"
                [maxDate]="maxDate"
                name="endDate"
                id="endDate"
                ngbDatepicker
                #ed="ngbDatepicker"
                (focusout)="checkendDtValidation()"
              />
              <div class="datepicker input-group-append">
                <button
                  style="
                    border: none;
                    outline: none;
                    background-color: transparent;
                    height: 45px;
                    margin-left: 20px;
                  "
                  (click)="ed.toggle()"
                  (focusout)="checkendDtValidation()"
                  type="button"
                >
                  <img
                    style="width: 100%"
                    src="../../../../assets/activityschedule.png"
                    alt="activityschedule"
                  />
                </button>
              </div>
            </div>
            <span
              *ngIf="
                eventForm.get('endDate').hasError('required') &&
                (eventForm.get('endDate').dirty ||
                  eventForm.get('endDate').touched)
              "
              class="mt-2 text-red"
            >
              End Date is required
            </span>
            <span
              *ngIf="
                eventForm.get('endDate').hasError('pattern') &&
                (eventForm.get('endDate').dirty ||
                  eventForm.get('endDate').touched)
              "
              class="mt-2 text-red"
            >
              Enter date in MM/DD/YYYY format
            </span>
            <span
              *ngIf="
                eventForm.get('endDate').hasError('lessThanT') &&
                (eventForm.get('endDate').dirty ||
                  eventForm.get('endDate').touched)
              "
              class="mt-2 text-red"
            >
              Date cannot be before the start date of the Task
            </span>
            <span
              *ngIf="
                eventForm.get('endDate').hasError('greaterThanMT') &&
                (eventForm.get('endDate').dirty ||
                  eventForm.get('endDate').touched)
              "
              class="mt-2 text-red"
            >
              Date cannot be after the end date of the Project Phase
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="postComment.length > 0">
          <div
            class="form-group col-lg-6 col-md-8"
            style="border: 1px solid #58595b"
          >
            <div style="max-height: 118px; overflow-y: scroll">
              <div *ngFor="let comments of postComment; let i = index">
                <span>- {{ comments.name }}</span>
                :<span style="font-weight: bold"> {{ comments.remarks }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="taskType == 'checkpoint'">
          <div class="form-group col-md-12 col-lg-12" style="margin-top: 9px">
            <label
              class="form-control-placeholder date-placeholder"
              for="taskName"
              >KPI</label
            >
            <div class="project-partners" id="kpisTag">
              <div class="content-tags">
                <div class="tag" *ngFor="let orgn of listOfKpis; let i = index">
                  <span class="tag-info">
                    <img
                      (click)="unselect1(i)"
                      class="cross-icon-red"
                      src="../../../../../assets/cross_icon_red.svg"
                      alt="calendar"
                    />
                    {{ orgn }}</span
                  >
                </div>
              </div>

              <div class="expander">
                <a
                  ><img
                    (click)="showKpis()"
                    class="expander-img"
                    src="../../../../../assets/expand.png"
                    alt="calendar"
                    style="margin-bottom: -135px; margin-left: 14px"
                    ngbTooltip="Add Kpi"
                /></a>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="commentHeader">
          <span
            class="commentBody"
            *ngIf="taskType != 'majorTask' || showCommentArrow"
            (click)="goToComment()"
            >Comments <i class="fa fa-arrow-right" aria-hidden="true"></i
          ></span>
        </div>
      </div>

      <div class="textAreaHeader" *ngIf="showTextArea">
        <span (click)="onBack()" class="commentBody"
          ><i class="fa fa-arrow-left" aria-hidden="true"></i>Back
        </span>
        <div *ngIf="postComment.length" class="textHeader">
          <div *ngFor="let comments of postComment; let i = index">
            <div>
              <span
                >- {{ comments.name }} <span>added a comment</span>
                {{ comments.date | date : "MMM d, y, h:mm:ss a" }}</span
              >
              <div class="commentAdd" class="remarks">
                {{ comments.remarks }}
              </div>
              <div style="margin-left: 87%; text-decoration: underline">
                <a
                  *ngIf="comments.delete"
                  (click)="onDeleteComment(i)"
                  style="margin-left: -2px; margin-right: 11px"
                  >Delete</a
                >
                <a (click)="onEditComment(i)">Edit</a>
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div *ngIf="!postComment">
          No comments to display.Click on edit to add comments.
        </div>

        <div *ngIf="toggleTextArea">
          <textarea
            [(ngModel)]="comment"
            [ngModelOptions]="{ standalone: true }"
            (keyup.enter)="onCommentAdd()"
            cols="57"
          ></textarea>
        </div>
        <div class="editComment">
          <a class="clearComment" (click)="onCommentCancel()">Clear</a>
          <a class="addComment" (click)="onCommentAdd()">Add</a>
        </div>
      </div>

      <ng-container *ngIf="kpiData">
        <h2 style="margin-left: 16px">Add KPI</h2>
        <div formArrayName="name1">
          <div *ngFor="let k of kpis; let i = index" style="margin: 1rem">
            <div
              class="form-check form-check-inline custom-control custom-checkbox"
            >
              <input
                [checked]="listOfKpis?.includes(k.kpiName)"
                class="form-check-input custom-control-input"
                type="checkbox"
                id="chk_{{ i }}"
                value="option1"
                (change)="onChange(kpis[i].kpiName, $event.target.checked)"
              />
              <label
                class="form-check-label custom-control-label"
                for="chk_{{ i }}"
              >
                <img
                  *ngIf="k.isParent"
                  src="../../../../assets/letter-p.png"
                  alt="parent-icon"
                />
                <img
                  *ngIf="!k.isParent"
                  src="../../../../assets/letter-c.png"
                  alt="child-icon"
                />
                {{ k.kpiName }}</label
              >
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="kpiData && kpis.length <= 0">
        There are no kpis to display!
      </ng-container>

      <div class="modal-footer">
        <button
          type="button"
          class="btn red-outline-button button-rounded mt-2"
          (click)="close()"
        >
          Cancel
        </button>
        <span style="float: right">
          <ng-container *RoleShow="['SIPM-Admin', 'SIPM-ProjectLead']">
            <span
              class="input-group-append float-left mt-2 mr-3"
              *ngIf="
                actionType != 'create' && actionType != 'edit' && !showTextArea
              "
            >
              <img
                class="mr-2"
                src="assets/copy-icon.png"
                alt="copy_icon"
                ngbtooltip="create a copy"
                (click)="copyData()"
                ng-reflect-ngb-tooltip="create a copy"
              />

              <img
                src="assets/garbage.svg"
                alt="delete"
                (click)="deleteConfirmModal()"
              />
            </span>
          </ng-container>
          <ng-container>
            <button
              *ngIf="
                actionType != 'create' &&
                actionType != 'edit' &&
                taskType != 'majorTask' &&
                isAssign &&
                !showTextArea
              "
              type="button"
              class="btn red-button button-rounded mr-2 mt-2"
              (click)="editTask()"
            >
              Edit
            </button>
            <button
              *ngIf="taskType == 'majorTask' && isAssign"
              type="button"
              class="btn red-button button-rounded mr-2 mt-2"
              (click)="editMajorTask()"
            >
              Edit
            </button>
          </ng-container>
          <ng-container
            *RoleShow="['SIPM-Admin', 'SIPM-ProjectLead', 'SIPM-ToyotaUser']"
          >
            <button
              *ngIf="taskType == 'majorTask' && showAddEventBtn"
              type="button"
              class="btn red-button button-rounded mt-2"
              (click)="createTaskFromMT()"
            >
              Add Event
            </button>
          </ng-container>
          <button
            type="submit"
            *ngIf="showSaveBtn && !showTextArea"
            class="btn red-button button-rounded mt-2"
            [disabled]="eventForm.invalid"
          >
            Save
          </button>
          <button
            *ngIf="kpiData"
            type="button"
            class="btn red-button button-rounded mt-2"
            (click)="onKpiSelect()"
          >
            OK
          </button>
        </span>
      </div>
    </form>
  </div>
</div>
