<app-header></app-header>

<app-side-bar></app-side-bar>
<div id="page-container">
  <router-outlet></router-outlet>
</div>
<div
  class="mobile-overlay"
  (click)="hideSideBar()"
  [ngClass]="{ collapsed: collapsedSideBar }"
></div>
