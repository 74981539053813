<input value="Export to PDF" type="button" id="export" (click)='exportToPDF()' class="hidden">

<div [ngClass]="noRecordsFlag ? '' : 'd-none'" class="mt-4">No records available</div>
<div [ngClass]="noRecordsFlag ? 'd-none': ''">
    
    
    <div>
        <input type="text" class="filter-text" (keyup)="searchTerm($event)" placeholder="Search by Name" />
        <a (click)="toggleTaskTree()">
          <img *ngIf="collapse" src="../../../../assets/grey_collapse_all.svg" class="collapse-icon" ngbTooltip="collapse all"
            alt="menu" />
          <img *ngIf="!collapse" src="../../../../assets/grey_expand_all.svg" class="collapse-icon" ngbTooltip="expand all"
            alt="menu" />
        </a>
      </div>
    <div #ganttHere id="ganttHere" class='gantt-chart mt-2'></div>
</div>