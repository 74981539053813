<div class="modal-content" id="enableModal">
  <div class="modal-header">
    <h5 class="text-center modal-title">{{ operation }}</h5>
  </div>
  <div class="text-center modal-body">
    <p>{{ modalBody }}</p>
    <p  style="font-weight: 600;" class="project">{{message}}</p>
    <div class="project" *ngIf="dependentEntities?.length > 0">
      <ul *ngFor="let x of dependentEntities">
        <li><p style="font-weight: 600;">{{x.entityType}}</p>
          <ul>
            <li *ngFor="let y of x.names">
              {{y}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="project" *ngIf="childDep?.length > 0">
      <p style="font-weight: 600;">Child Project:</p>
      <ul *ngFor="let x of childDep">
        <li>{{x.type}}
          <ul>
            <li *ngFor="let y of x.value">
              {{y}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="project" *ngIf="parentDependencyData?.length > 0">
      <p  style="font-weight: 600;">Parent Project:</p>
      <ul *ngFor="let x of parentDependencyData">
        <li>{{x.type}}
          <ul class="displayColor">
            <li  *ngFor="let y of x.value">
              {{y}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div *ngIf="fromCopy">
      <div>
        <label for="fname">New Project Name:</label>
        <input type="text" [(ngModel)]="projectName" id="fname" name="fname" />
        <div class="modal-footer"></div>
      </div>
      <div>
        <label for="fname">New Project Code:</label>
        <input type="text" [(ngModel)]="ProjectCode" id="fname" name="fname" />
        <div class="modal-footer"></div>
      </div>
    </div>
    <button
      type="button"
      *ngIf="noBtn"
      class="btn red-outline-button button-rounded"
      (click)="activeModal.close(false)"
    >
      {{ noBtnName }}
    </button>
    &nbsp;&nbsp;
    <button
      type="button"
      *ngIf="yesBtn"
      (click)="activeModal.close(true); onClick()"
      class="btn red-button button-rounded"
    >
      {{ yesBtnName }}
    </button>
  </div>
</div>
