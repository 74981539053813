import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Kpi } from './kpi.model';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  public kpiMessage = new BehaviorSubject('defalute');
  currentMessage = this.kpiMessage.asObservable();
  kpiData: any;
  formData: any;
  id: any;
  selectedkpi: Kpi;
  duplicateMessage: any;
  constructor(private readonly http: HttpClient) {}

  getKPIData(projectCode): Observable<Kpi[]> {
    return this.http
      .get(
        `${environment.baseUrl}/project?projectCode=${projectCode}&fetch=kpi`
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  getKpi(kpiId: number): Observable<Kpi> {
    return this.http.get<Kpi>(`api/kpi/${kpiId}`);
  }

  addKpi(kpi: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/project-misc/kpi`, kpi, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  updateKpi(kpi) {
    return this.http.put(
      `${environment.baseUrl}/project-misc/kpi?action=update`,
      kpi
    );
  }
  deleteKpi(kpi): Observable<any> {
    return this.http.put(
      `${environment.baseUrl}/project-misc/kpi?action=delete`,
      kpi
    );
  }

  sendDuplicate(message) {
    this.duplicateMessage = message;
  }
  //Fetch the form data from user
  FormData(data) {
    this.formData = data;
  }

  //set the value of id
  setId(value) {
    this.id = value + 1;
  }

  setKpiDataStore(data) {
    sessionStorage.setItem('kpiData', JSON.stringify(data));
  }

  getKpiDataStore() {
    return JSON.parse(sessionStorage.getItem('kpiData'));
  }
}
