export class Task {
  id: number;
  start_date: string;
  text: string;
  progress: number;
  end_date: string;
  parent: number;
  type: any;
  color: string;
  align: string;
  render: string;
  assignTo: string;
  eventType: string;
  status: string;
  isClicked: boolean;
  majorTaskName: string;
  isMismatchassignto: string;
  isMismatchdate: string;
  comments: any[];
  kpis: any[];
  dataEditable: boolean;
  userRole: string;
  majorTaskUUID: any;
  subTaskUUID: any;
  constructor(
    id,
    startDate,
    text,
    progress,
    endDate,
    parent,
    type,
    color,
    align,
    render,
    assignTo,
    eventType,
    status,
    isClicked,
    majorTaskName,
    isMismatchassignto,
    isMismatchdate,
    comments,
    kpis,
    dataEditable,
    userRole,
    majorTaskUUID,
    subTaskUUID
  ) {
    this.id = id;
    this.start_date = startDate;
    this.text = text;
    this.progress = progress;
    this.end_date = endDate;
    this.parent = parent;
    this.type = type;
    this.color = color;
    this.align = align;
    this.render = render;
    this.assignTo = assignTo;
    this.eventType = eventType;
    this.status = status;
    this.isClicked = isClicked;
    this.majorTaskName = majorTaskName;
    this.isMismatchassignto = isMismatchassignto;
    this.isMismatchdate = isMismatchdate;
    this.comments = comments;
    this.kpis = kpis;
    this.dataEditable = dataEditable;
    this.userRole = userRole;
    this.majorTaskUUID = majorTaskUUID;
    this.subTaskUUID = subTaskUUID;
  }
}

export class TaskDashboard {
  id: number;
  start_date: string;
  text: string;
  progress: number;
  end_date: string;
  parent: number;
  type: any;
  color: string;
  align: string;
  render: string;
  status: string;
  constructor(
    id,
    startDate,
    text,
    progress,
    endDate,
    parent,
    type,
    color,
    align,
    render,
    status
  ) {
    this.id = id;
    this.start_date = startDate;
    this.text = text;
    this.progress = progress;
    this.end_date = endDate;
    this.parent = parent;
    this.type = type;
    this.color = color;
    this.align = align;
    this.render = render;
    this.status = status;
  }
}
