export const environment = {
  name: 'local',
  production: false,
  baseUrl: 'https://u8h4nvofza.execute-api.us-west-2.amazonaws.com/dev/',
  clientId: '50313f50-046d-4b59-9bd4-5993780d2220',
  authority:
    'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef/',
  redirectUri: 'http://localhost:4200',
  postLogoutRedirectUri: 'https://dev.sipm.toyota.com/dashboard',
  scope: 'https://dev.api.sipm.toyota.com/SIPMAPI.All',
};
