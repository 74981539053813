import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalConfig } from '../../core/services/models/modal-config';

@Injectable({
  providedIn: 'root',
})
export class ModalStateService {
  private readonly configSource = new BehaviorSubject<ModalConfig>(
    new ModalConfig()
  );
  config = this.configSource.asObservable();

  constructor() {}

  changeConfig(change: ModalConfig) {
    this.configSource.next(change);
  }
}
