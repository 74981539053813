export const ZoomConfig = {
  levels: [
    {
      name: 'day',
      scale_height: 27,
      min_column_width: 60,
      scales: [{ unit: 'day', step: 1, format: '%m/%d/%y' }],
    },
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 50,
      scales: [
        // {
        //   unit: 'week',
        //   step: 1,
        //   format: function (date) {
        //     var dateToStr = gantt.gantt.date.date_to_str('%d %M');
        //     var endDate = gantt.gantt.date.add(date, -6, 'day');
        //     var weekNum = gantt.gantt.date.date_to_str('%W')(date);
        //     return (
        //       '#' +
        //       weekNum +
        //       ', ' +
        //       dateToStr(date) +
        //       ' - ' +
        //       dateToStr(endDate)
        //     );
        //   },
        // },
        { unit: 'week', step: 1, format: '%m/%d' },
      ],
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 120,
      scales: [
        { unit: 'month', format: '%F, %Y' },
        // { unit: 'week', format: 'Week #%W' },
      ],
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 30,
      scales: [{ unit: 'year', step: 1, format: '%Y' }],
    },
  ],
};
