import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { PaginationComponent } from './pagination/pagination.component';
import { GanttComponent } from './gantt/gantt.component';
import { HelpComponent } from './help/help.component';
import { RoleShowDirective } from './directive/role-show.directive';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BreadCrumbComponent,
    PaginationComponent,
    GanttComponent,
    HelpComponent,
    RoleShowDirective,
    AlertComponent,
    DateTimePickerComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, NgbModule, NgSelectModule],
  exports: [
    AlertComponent,
    BreadCrumbComponent,
    PaginationComponent,
    GanttComponent,
    RoleShowDirective,
    HelpComponent,
    DateTimePickerComponent,
  ],
})
export class SharedModule {}
