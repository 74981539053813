import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModalComponent } from '../activity-schedule/event-modal/event-modal.component';


@Component({
    selector: 'app-notification',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModal implements OnInit {
    projectDetails: any = [];
    taskResData: any = [];
    index: any;
    milestoneArray: any = [];

    constructor(public readonly activeModal: NgbActiveModal, private readonly modalService: NgbModal,
        public readonly router: Router
    ) { }

    ngOnInit() {
        this.projectDetails = JSON.parse(sessionStorage.getItem('parentProject'));
        const childProjects = []
        this.projectDetails.ChildProject.forEach((x) => {
          x.majorTasks.forEach((y) => {
            y.childProjectCode = x.projectCode;
            childProjects.push(y);
          });
        });
        const childResData = [];
        childProjects.forEach(x=>{
          x.tasks.forEach(y => {
            y.projectType = 'Child'
              childResData.push(y)
          });
        })
        const parentResData = [];
        this.projectDetails.ParentProject.majorTasks.forEach(x=>{
          x.tasks.forEach(y => {
            y.projectType = 'Parent'
            parentResData.push(y);
          });
        })
        const mileStoneData = [...childResData,...parentResData];
        mileStoneData.sort((a, b) =>
        new Date(a['startDate'])
          .toISOString()
          .localeCompare(new Date(b['startDate']).toISOString())
      )
        mileStoneData.forEach(x=>{
            if(x.type === "Milestone" && new Date() <= new Date(x.startDate) && this.milestoneArray.length < 3){
              this.milestoneArray.push(x)
            }
        });
    }
    moveTo(data) {
        this.activeModal.close(true);
        if(data.projectType==='Parent'){
            this.projectDetails.ParentProject.majorTasks.forEach(x => {
                x.tasks.forEach(y => {
                    if (y.subTaskUUID === data.subTaskUUID) {
                        this.taskResData.push(x)
                    }
                });
            });
        this.index = this.taskResData[0].tasks.findIndex(x=> x.subTaskUUID === data.subTaskUUID);
        }else{
            const childProjectsData = [];
            const childResData = [];
            this.projectDetails.ChildProject.forEach(a=>{
                a.majorTasks.forEach((b) => {
                    b.childProjectCode = a.projectCode;
                    childProjectsData.push(b);
                  });
            });
            childProjectsData.forEach(c=>{
                c.tasks.forEach(d => {
                    if (d.subTaskUUID === data.subTaskUUID) {
                        this.taskResData.push(c)
                    }
                });
              })
              this.index = this.taskResData[0].tasks.findIndex(x=> x.subTaskUUID === data.subTaskUUID);
        }
        
        const modalRef = this.modalService.open(EventModalComponent, {
            size: 'lg',
            centered: false,
            backdrop: 'static'
        });
        modalRef.componentInstance.notificationData = this.taskResData[0];
        modalRef.componentInstance.index = this.index;
       
    }
}

