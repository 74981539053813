<!-- Risk section Starts here -->

<div
  class="risk-section"
  *ngIf="
    label === 'Risks/Obstacles' ||
    label === 'Create Risks/Obstacles' ||
    label === 'View Risks/Obstacles' ||
    label === 'Edit Risks/Obstacles'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Group_1060.svg"
        HEAD
        alt="group"
        alt="instructions risk"
      />
      <p class="p_instructions">Instructions to Risk Management</p>
    </div>

    <p class="utilize">
      Utilize this structure to visualize your risk management plan.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Consider the purpose and intended results of
        your project.
      </li>
      <li>
        <strong>Step 2:</strong> Identify potential risk that will impact the
        intended results
      </li>
      <li>
        <strong>Step 3:</strong> Classify the risk based on how much impact it
        will have own your intended results
      </li>
      <li>
        <strong>Step 4:</strong> Develop a risk management plan for each risk
        which included KPIs and timing to check for progress.
      </li>
    </ul>
  </div>
</div>

<!-- Risk Section ends here -->

<!-- Communication Matrix Starts here -->

<div
  class="Communication-Matrix-section"
  *ngIf="
    label === 'Meetings' ||
    label === 'Edit Meetings' ||
    label === 'Create Meetings' ||
    label === 'View Meetings'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          (click)="close()"
          alt="close"
          src="../../../assets/cross_icon.svg"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Communi_Group-763.svg "
        alt="communication-matrix"
      />
      <p class="p_instructions">Instructions to Meetings</p>
    </div>

    <p class="utilize">
      Utilize this structure to visualize all necessary- project meetings and
      timing for each. Also include the definition of the meeting.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Identify all information about the project that
        should be shared with members of the team.
      </li>
      <li>
        <strong>Step 2:</strong> Consider the cadence of meetings. How often
        will each meeting occur?
      </li>
      <li>
        <strong>Step 3:</strong> Develop a draft communication matrix to
        visualize purpose, audience and timing of each meeting.
      </li>
      <li>
        <strong>Step 4:</strong> Negotiate your draft with all partners and
        members of the project until consensus is complete.
      </li>
    </ul>
  </div>
</div>

<!-- Communication Matrix ends here -->

<!-- Activity Schedule Starts here -->

<div class="Activity-Schedule-section" *ngIf="label === 'Activity Schedule'">
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Activity_Group_1062.svg"
        alt="activity schedule instructions"
      />
      <p class="p_instructions">Instructions to Activity Schedule</p>
    </div>

    <p class="utilize">
      Use this document to manage your project day to day. Detailed tasks to
      ensure forward progress are shown here.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Start with the end(intended results) in mind.
      </li>
      <li>
        <strong>Step 2:</strong> Working backwards, identify key milestones to
        reach the end result.
      </li>
      <li>
        <strong>Step 3:</strong> Develop a draft schedule that includes
        checkpoints and key activity to move the project forward.
      </li>
      <li>
        <strong>Step 4:</strong> Negotiate with all related stakeholders for
        consensus and understanding of the schedule.
      </li>
      <li>
        <strong>Step 5:</strong> Consistently check and gauge progress of
        project. Adjust when needed - after stakeholders have bought into change
        points.
      </li>
    </ul>
  </div>
</div>

<!-- Activity Schedule ends here -->

<!-- KPI Starts here -->

<div
  class="KPI-section"
  *ngIf="label === 'KPI' || label === 'Create KPI' || label === 'View KPI'"
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/KPI_Group_1061.svg"
        HEAD
        alt="asset"
        alt="kpi instructions"
      />
      <p class="p_instructions">Instructions to KPI</p>
    </div>

    <p class="utilize">
      Utilize this structure to visualize your Key Performance Indicators.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Identify S.M.A.R.T. targets that will allow
        clear measurement of success.
      </li>
      <li>
        <strong>Step 2:</strong> Create a draft of your KPIs to negotiate with
        all related decision makers (reference your R&R matrix).
      </li>
      <li>
        <strong>Step 3:</strong> Determine when each KPI will be measured during
        the projects life cycle.
      </li>
      <li>
        <strong>Step 4:</strong> Negotiate and gain consensus from all key
        decision makers (reference your R&R matrix).
      </li>
      <li>
        <strong>Step 5:</strong> After consensus with all stakeholders - monitor
        your risk and KPIs through the duration of your project.
      </li>
    </ul>
  </div>
</div>

<!-- KPI ends here -->

<!-- Roles Starts here -->

<div
  class="Roles-section"
  *ngIf="
    label === 'Roles & Responsibility' ||
    label === 'Edit Roles&Responsibilty/ Project Phase' ||
    label === 'Create Roles&Responsibilty/ Project Phase' ||
    label === 'View Roles&Responsibilty/ Project Phase'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Roles_Group_1059.svg"
        HEAD
        alt="roles"
        alt="group icon"
      />
      <p class="p_instructions">Instructions to Roles & Responsibility</p>
    </div>

    <p class="utilize">
      Utilize this structure to clarify who will do each activity for the
      project.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Start with the end (intended results) in mind.
      </li>
      <li>
        <strong>Step 2:</strong> Working backwards, identify key milestones to
        reach the end result.
      </li>
      <li>
        <strong>Step 3:</strong> Develop a draft schedule that includes
        checkpoints and key activity to move the project forward.
      </li>
      <li>
        <strong>Step 4:</strong> Negotiate with all related stakeholders for
        consensus and understanding of the schedule.
      </li>
      <li>
        <strong>Step 5:</strong> Consistently check and gauge progress of
        project. Adjust when needed - after stakeholders have bought into change
        points.
      </li>
    </ul>
  </div>
</div>

<!-- Roles ends here -->

<!-- Organisation Data Sheet Starts here -->

<div class="Organisation-section" *ngIf="label === 'Partner Org Chart'">
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          (click)="close()"
          alt="close"
          src="../../../assets/cross_icon.svg"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/org_diagram.svg"
        HEAD
        alt="org_diagram"
        alt="instructions"
      />
      <p class="p_instructions">Instructions to Partner Org Chart</p>
    </div>

    <p class="utilize">
      List all members of the project in one location. Include phone number and
      email for easy access to members. This structure will auto populate other
      documents.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Investigator and agree to partners necessary
        for your project.
      </li>
      <li>
        <strong>Step 2:</strong> Agree on program leaders for each partner.
      </li>
      <li><strong>Step 3:</strong> Negotiate the structure of your project.</li>
      <ol type="A">
        <li>Number of groups required.</li>
        <li>The leader for each group.</li>
      </ol>
      <li>
        <strong>Step 4:</strong> For each group, determine the amount of support
        required to properly manage the project.
      </li>
      <li>
        <strong>Step 5:</strong> Once you gain agreement on steps 1-5, enter all
        date on the datasheet. (Note: This sheet will auto-populate the other
        structures. Links are included on the right.)
      </li>
    </ul>
  </div>
</div>

<!-- Organisation ends here -->

<!-- Project Starts here -->

<div
  class="Project-section"
  *ngIf="
    label === 'Project/Program' ||
    label === 'Edit Project/Program' ||
    label === 'Create Project/Program' ||
    label === 'View Project/Program'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          (click)="close()"
          alt="close"
          src="../../../assets/cross_icon.svg"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Project_Group_1063.svg"
        alt="project"
        alt="instructions"
      />
      <p class="p_instructions">Instructions to start a project</p>
    </div>

    <p class="utilize">
      Utilize this Scope Summary to report out to executives and / or include in
      the project strategy. This structure will auto populate.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Clarify the Purpose by asking What and Why.
        Clarify the intended results then fill in the Purpose Matrix.
      </li>
      <li>
        <strong>Step 2:</strong> Investigate resources required for the
        project.(Time, Money, People, Partners, Materials)
      </li>
      <li>
        <strong>Step 3:</strong> Select the appropriate measurement for each
        resource. The average of your measurements will create your project
        scope rating.
      </li>
    </ul>
  </div>
</div>

<!-- Project ends here -->

<!-- Budget section Starts here -->

<div
  class="Budget-section"
  *ngIf="
    label === 'Budget' || label === 'Create Budget' || label === 'Edit Budget'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/Group_1060.svg"
        alt="group"
        alt="instructions"
      />
      <p class="p_instructions">Instructions to Budget Management</p>
    </div>

    <p class="utilize">
      Utilize this structure to visualize the budget management for the project.
    </p>

    <ul>
      <li>
        <strong>Step 1:</strong> Validate approved budget amount and cost center
        information from the SI Annual Plan tool for the project.
      </li>
      <li>
        <strong>Step 2:</strong> Identify and add planned budget items with
        schedule to fund the project.
      </li>
      <li>
        <strong>Step 3:</strong> Update balance sheet when expenses are
        initiated.
      </li>
      <li>
        <strong>Step 4:</strong> Validate initiated expenses are reflected in
        the SI Dashboard Spend Summary Detailed report spend section.
      </li>
      <li>
        <strong>Step 5:</strong> Report any timing modifications to spend for
        reforecast in June, September, and December to OSI Operations Budget
        team.
      </li>
    </ul>
  </div>
</div>

<!-- Budget Section ends here -->

<!-- Meeting section Starts here -->

<div
  class="Meeting-section"
  *ngIf="
    label === 'Create Meeting Minutes' ||
    label === 'View Meeting Minutes' ||
    label === 'Edit Meeting Minutes'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/calendar-1.svg"
        alt="calendar"
      />
      <p class="p_instructions">Instructions to Meeting Minutes</p>
    </div>

    <p class="utilize">
      Utilize this document as a quick and easy tool to capture notes and clear
      follow up items.
    </p>

    <ul>
      <li><strong>Step 1:</strong> Capture the discussions key point.</li>
      <li>
        <strong>Step 2:</strong> Identify any next steps necessary from the
        discussion and record.
      </li>
      <li>
        <strong>Step 3:</strong> Confirm the responsible person for the next
        steps (one by one) and note in the tool.
      </li>
      <li>
        <strong>Step 4:</strong> Confirm the timing to complete the next steps
        (one by one). Confirm the responsible person agrees with your notes.
      </li>
    </ul>
  </div>
</div>

<!-- Meeting Section ends here -->
<!-- Group Starts here -->

<div class="Group" *ngIf="label === 'Group'">
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/master.svg"
        alt="calendar"
      />
      <p class="p_instructions">Instructions to Group</p>
    </div>

    <p class="utilize">Utilize this structure to create and manage groups.</p>

    <ul>
      <li>
        <strong>Note 1:</strong>Any group which has dependency on other
        pages(Project,Acivity Schedule,Communication Matrix) cannot be deleted
        without deleting the dependencies first.
      </li>
      <li>
        <strong>Note 2:</strong>To get latest data for phases please logout and
        loggin again.
      </li>
    </ul>
  </div>
</div>

<!-- Group  ends here -->
<!-- Phase Starts here -->

<div class="Group" *ngIf="label === 'Phase'">
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/master.svg"
        alt="calendar"
      />
      <p class="p_instructions">Instructions to Phase</p>
    </div>

    <p class="utilize">Utilize this structure to create and manage phase.</p>

    <ul>
      <li>
        <strong>Note 1:</strong> Any phase which has dependency on other
        pages(Project,Acivity Schedule,Meeting Minutes) cannot be deleted
        without deleting the dependencies first
      </li>
      <li>
        <strong>Note 2:</strong>To get latest data for phases please logout and
        loggin again.
      </li>
    </ul>
  </div>
</div>

<!-- Phase  ends here -->
<!-- Partner Starts here -->

<div
  class="Group"
  *ngIf="
    label === 'Partner' ||
    label === 'Create Partner' ||
    label === 'View Partner' ||
    label === 'Edit Partner'
  "
>
  <div class="container">
    <div class="row helpRow">
      <div class="col-sm-6">
        <p class="help_text">Help</p>
      </div>
      <div class="col-sm-6">
        <img
          class="cross_icon"
          src="../../../assets/cross_icon.svg"
          alt="close"
          (click)="close()"
          alt="close icon"
        />
      </div>
    </div>
  </div>

  <div class="info">
    <div class="instructions">
      <img
        class="img_instructions"
        src="../../../assets/master.svg"
        alt="calendar"
      />
      <p class="p_instructions">Instructions to Partner</p>
    </div>

    <p class="utilize">Utilize this structure to create and manage Partner.</p>

    <ul>
      <li>
        <strong>Note 1:</strong> Any Partner which has dependency on other
        pages(Project,Acivity Schedule,meeting Minutes) cannot be deleted
        without deleting the dependencies first
      </li>
      <li><strong>Note 2:</strong>'Toyota' Partner cannot be deleted.</li>
    </ul>
  </div>
</div>

<!-- Partner  ends here -->
