import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  pageData: any;
  pager: any = {};
  pagedItems: any[];
  pageTotal: number;
  lastSecondPage: number;
  totalitems: number;

  @Input() offset: any;
  @Output() pagestartIndex = new EventEmitter();

  currentPage: number;
  totalPages: number;
  pages: Observable<number[]>;
  datalength: any;
  si: any;
  ei: number;
  constructor() {}

  ngOnInit(): void {
    this.setPage(1);
  }
  ngOnChanges(): void {
    this.setPage(1);
  }
  getPager(totalItems: number, currentPage = 1, pageSize = 15): any {
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number;
    let endPage: number;
    this.pageTotal = totalPages;
    this.totalitems = totalItems;

    this.lastSecondPage = totalPages - 1;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        if (totalPages - (currentPage - 2) === 5) {
          startPage = currentPage - 1;
          endPage = currentPage + 3;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    const pages = _.range(startPage, endPage + 1);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  setPage(page: number): void {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.offset, page);

    this.si = this.pager.startIndex + 1;
    this.ei = this.pager.endIndex + 1;
    this.pagestartIndex.emit(this.pager.startIndex);
  }
}
