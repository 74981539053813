import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as gantt from '../../../assets/gantt/codebase/dhtmlxgantt';
import { ActivityScheduleService } from '../../core/services/activity-schedule/activity-schedule.service';
import { TaskDashboard } from '../../../assets/model/task';
import { ModalConfig } from '../../core/services/models/modal-config';
import { UserService } from '../../core/services/user/user.service';
import { AlertComponent } from '../alert/alert.component';
import { ZoomConfig } from '../config/zoomLevel.config';
import { ModalStateService } from '../services/modal-state.service';
import '../../../assets/gantt/codebase/api.js';
import { Status } from '../config/status.config';
import { formatDate } from '@angular/common';
import { environment } from '../../../environments/environment';

let GanttInstanceDashboard = gantt.Gantt.getGanttInstance();
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-gantt',
  styleUrls: ['./gantt.component.scss'],
  templateUrl: './gantt.component.html',
})
export class GanttComponent implements OnInit, OnDestroy {
  @ViewChild('ganttHere') ganttContainer: ElementRef;
  taskData: any[];
  @Output() stopSpinner = new EventEmitter<any>();
  pjCd: string;
  milestoneData: any;
  noRecordsFlag = false;
  collapse: any;
  _searchTerm: any;
  filtering: any = 'all';
  projectData: any = [];
  taskResData: any = [];
  projectResData: any = [];

  taskDetails: any;
  taskDetails1: any;
  taskDashboard: any = [];
  ganttTasks: any;
  childProjects: any = [];

  constructor(
    private readonly activityScheduleService: ActivityScheduleService,
    private readonly _userSrv: UserService,
    private readonly _modalService: NgbModal,
    private readonly modalState: ModalStateService
  ) {}

  ngOnInit(): void {
    this.stopSpinner.emit(); //temporary change
    if (GanttInstanceDashboard === null) {
      GanttInstanceDashboard = gantt.Gantt.getGanttInstance();
    }
    this._userSrv.currentPrj.subscribe((data) => {
      this.pjCd = data.projectCode;
      if (this.pjCd.length === 0) {
        this.pjCd = sessionStorage.getItem('currentPrj');
      }
      this.noRecordsFlag = false;
      this.milestoneGanttChart();
    });
  }

  ngAfterViewInit() {
    GanttInstanceDashboard.plugins({
      tooltip: true,
      marker: true,
    });
    const currentD = formatDate(new Date(), 'MM-dd-yyyy', 'en_US');
    const currentDate = new Date(currentD);
    GanttInstanceDashboard.config.columns = [
      {
        name: 'text',
        label: 'Name',
        tree: true,
        min_width: 50,
        width: 200,
        resize: true,
        template: (task) => {
          if (task.id.includes('f_0')) {
            return `<img src="${environment.redirectUri}/assets/letter-p.png" class="icon-align" alt="parent-icon" />${task.text}`;
          } else if (task.id.includes('f')) {
            return `<img src="../../../../assets/letter-c.png" class="icon-align" alt="child-icon" />${task.text}`;
          } else {
            if (task.end_date < currentDate && task.progress !== 1) {
              return `<div class='over-due'>${task.text}</div>`;
            } else {
              return `${task.text}`;
            }
          }
        },
      },
      {
        name: 'progress',
        label: 'Progress',
        align: 'center',
        template: function (task) {
          if (task.id.charAt(0) === 'f') {
            return '';
          }
          if (task.progress * 100 === 100) {
            return task.progress
              ? `${(task.progress * 100).toPrecision(3)}%`
              : '0%';
          }
          return task.progress
            ? `${(task.progress * 100).toPrecision(2)}%`
            : '0%';
        },
      },
      { name: 'start_date', label: 'Start Date', align: 'center', hide: true },
      {
        name: 'status',
        label: 'Status',
        align: 'center',
        template: function (task) {
          return Status.get(task.render);
        },
      },
    ];
    GanttInstanceDashboard.config.sort = true;
    GanttInstanceDashboard.ext.zoom.init(ZoomConfig);
    GanttInstanceDashboard.ext.zoom.setLevel('week');
    GanttInstanceDashboard.config.initial_scroll = false;
    GanttInstanceDashboard.init(this.ganttContainer.nativeElement);
  }

  milestoneGanttChart() {
    this.pjCd &&
      this.activityScheduleService
        .getTaskData(this.pjCd)
        .subscribe((res: any) => {
          if (res.status.code === '200') {
            this.stopSpinner.emit();
            this.taskDetails1 = res.data;
            this.childProjects = [];
            res.data.ChildProject.forEach((x) => {
              x.majorTasks.forEach((y) => {
                y.childProjectCode = x.projectCode;
                this.childProjects.push(y);
              });
            });
            this.projectResData = res.data;
            this.showNotificationIcon();
            this.activityScheduleService.getProjectData(this.taskResData);
            this.taskData =
              res.data.ParentProject.majorTasks &&
              res.data.ParentProject.majorTasks.length > 0
                ? res.data.ParentProject.majorTasks.sort((c, d) =>
                    new Date(c['startDate'])
                      .toISOString()
                      .localeCompare(new Date(d['startDate']).toISOString())
                  )
                : [];
            this.iterateTasks(this.taskDetails1.ParentProject, '0');
            this.taskDetails1.ChildProject.forEach((child, index) => {
              this.iterateTasks(child, ++index);
            });
            this.ganttTasks = this.taskDashboard;
            const tasks = this.taskDashboard;
            this.showGridBasedonSize();
            this.ganttTemplateStyles();
            this.sortUsingName();
            GanttInstanceDashboard.config.readonly = true;
            GanttInstanceDashboard.clearAll();
            GanttInstanceDashboard.parse({ tasks });
            this.invokeEvents();
          }
        });
  }
  sortUsingName() {
    var sort = false;
    GanttInstanceDashboard.config.columns[0].sort = function (a, b) {
      sort = !sort;
      a = a.text.toLowerCase();
      b = b.text.toLowerCase();
      if (sort) {
        return a < b ? 1 : a > b ? -1 : 0;
      } else {
        return a > b ? -1 : a < b ? 1 : 0;
      }
    };
  }
  showGridBasedonSize() {
    GanttInstanceDashboard.attachEvent(
      'onGridResizeEnd',
      (oldWidth, newWidth) => {
        if (newWidth < 80) {
          GanttInstanceDashboard.config.show_grid = false;
          GanttInstanceDashboard.render();
        } else {
          GanttInstanceDashboard.config.show_grid = true;
          GanttInstanceDashboard.render();
        }
        return true;
      },
      ''
    );
  }
  showNotificationIcon() {
    const childResData = [];
    this.childProjects.forEach((x) => {
      x.tasks.forEach((y) => {
        y.projectType = 'Child';
        childResData.push(y);
      });
    });
    sessionStorage.setItem(
      'parentProject',
      JSON.stringify(this.projectResData)
    );
    const parentResData = [];
    this.projectResData.ParentProject.majorTasks.forEach((x) => {
      x.tasks.forEach((y) => {
        y.projectType = 'Parent';
        parentResData.push(y);
      });
    });
    const mileStoneData = [...childResData, ...parentResData];
    mileStoneData.sort((a, b) =>
      new Date(a['startDate'])
        .toString()
        .localeCompare(new Date(b['startDate']).toString())
    );
    mileStoneData.forEach((x) => {
      if (
        x.type === 'Milestone' &&
        new Date() <= new Date(x.startDate) &&
        this.taskResData.length < 3
      ) {
        this.taskResData.push(x);
      }
    });
  }
  ganttTemplateStyles() {
    GanttInstanceDashboard.templates.leftside_text = function (
      start,
      end,
      task
    ) {
      if (task.type === GanttInstanceDashboard.config.types.milestone) {
        return task.text;
      }
      return '';
    };
    GanttInstanceDashboard.templates.tooltip_text = function (
      start,
      end,
      task
    ) {
      if (task.type === GanttInstanceDashboard.config.types.task) {
        if (task.progress * 100 === 100) {
          return `${task.text} (${(task.progress * 100).toPrecision(3)}%)`;
        }
        return `${task.text} (${(task.progress * 100).toPrecision(2)}%)`;
      }
      return '';
    };
  }
  iterateTasks(project, prjIndex) {
    GanttInstanceDashboard.config.types['checkpoint'] = 'checkpoint';
    const mtTaskproject = new TaskDashboard(
      `f_${prjIndex}`,
      GanttInstanceDashboard.date.parseDate(project.startDate, '%m/%d/%Y'),
      project.projectName,
      0,
      GanttInstanceDashboard.date.parseDate(project.endDate, '%m/%d/%Y'),
      0,
      GanttInstanceDashboard.config.types.project,
      '#65EDB8',
      'left',
      '',
      'Project'
    );
    this.taskDashboard.push(mtTaskproject);
    project.majorTasks.forEach((i, index) => {
      let color;
      if (i.eventType === 'majorTask' || i.eventType !== 'operational') {
        color = '#24B6FB';
      } else if (i.eventType === 'operational') {
        color = '#ff9966';
      }

      const mtTask = new TaskDashboard(
        `p_${prjIndex}_${index + 1}`,
        GanttInstanceDashboard.date.parseDate(i.startDate, '%m/%d/%Y'),
        i.majorTaskName,
        Math.round(i.progress) / 100,
        GanttInstanceDashboard.date.parseDate(i.endDate, '%m/%d/%Y'),
        `f_${prjIndex}`,
        GanttInstanceDashboard.config.types.task,
        color,
        'left',
        i.status,
        ''
      );
      this.taskDashboard.push(mtTask);
    });
    //return tasks;
  }

  invokeEvents() {
    GanttInstanceDashboard.attachEvent(
      'onGridResizeEnd',
      (oldWidth, newWidth) => {
        if (newWidth > 400) {
          GanttInstanceDashboard.getGridColumn('start_date').hide = false;
          GanttInstanceDashboard.config.show_grid = true;
          GanttInstanceDashboard.render();
        } else if (newWidth < 255) {
          GanttInstanceDashboard.config.show_grid = false;
        } else {
          GanttInstanceDashboard.getGridColumn('start_date').hide = true;
          GanttInstanceDashboard.config.show_grid = true;
          GanttInstanceDashboard.render();
        }
        return true;
      },
      ''
    );
    if (GanttInstanceDashboard !== null) {
      GanttInstanceDashboard.attachEvent(
        'onBeforeTaskDisplay',
        (id, task) => {
          if (this.typeCheck(id, this.filtering) && this.compare_input(id)) {
            return true;
          }
          return false;
        },
        ''
      );
    }
  }
  compare_input(id) {
    var match = false;
    if (this._searchTerm === undefined) {
      match = true;
    } else if (
      GanttInstanceDashboard.getTask(id)
        .text.toLowerCase()
        .indexOf(this._searchTerm.toLowerCase()) >= 0
    ) {
      match = true;
    }

    return match;
  }
  typeCheck(parent, type) {
    if (
      GanttInstanceDashboard.getTask(parent).type.toLowerCase() ===
        type.toLowerCase() ||
      type.toLowerCase() === 'all'
    ) {
      return true;
    }

    var child = GanttInstanceDashboard.getChildren(parent);
    for (var i = 0; i < child.length; i++) {
      if (this.typeCheck(child[i], type)) {
        return true;
      }
    }

    return false;
  }
  openErrorModal(): void {
    if (!this._modalService.hasOpenModals()) {
      const modalRef = this._modalService.open(AlertComponent, {
        centered: true,
      });
      const configGantt = new ModalConfig();
      configGantt.modalHeader = 'Error';
      configGantt.modalBody =
        'System is currently unavailable, please try after sometime.';
      configGantt.cancelBtn = true;
      configGantt.cancelBtnName = 'Close';
      configGantt.saveBtn = false;
      configGantt.saveBtnName = '';
      configGantt.deleteBtn = false;
      this.modalState.changeConfig(configGantt);
      modalRef.result
        .then((result) => {
          modalRef.close();
        })
        .catch((error) => {});
    }
  }
  exportToPDF() {
    const dept = document.getElementById('title').innerText;

    GanttInstanceDashboard.exportToPDF({
      name: `${dept}-chart.pdf`,
      header: `<style>h1{text-align:center}</style><h1>${dept}</h1>`,
      raw: true,
    });
  }
  searchTerm(e) {
    this._searchTerm = e.target.value;
    GanttInstanceDashboard.refreshData();
  }

  toggleTaskTree() {
    if (this.collapse) {
      GanttInstanceDashboard.eachTask(function (task) {
        GanttInstanceDashboard.close(task.id);
      });
      this.collapse = false;
    } else {
      GanttInstanceDashboard.eachTask(function (task) {
        GanttInstanceDashboard.open(task.id);
      });
      this.collapse = true;
    }
  }
  ngOnDestroy() {
    GanttInstanceDashboard.destructor();
    GanttInstanceDashboard = null;
  }
}
